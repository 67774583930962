import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.css']
})
export class TrendingComponent implements OnInit {

  constructor() { }

  trendingpost = [
    {img:'assets/img/offers/01.jpg',category:'double bed',title:'Luxury Double Bed',price:'345',period:'Month',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'},
    {img:'assets/img/offers/02.jpg',category:'double bed',title:'Luxury Double Bed',price:'345',period:'Month',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'},
    {img:'assets/img/offers/03.jpg',category:'double bed',title:'Luxury Double Bed',price:'345',period:'Month',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'},
    {img:'assets/img/offers/04.jpg',category:'double bed',title:'Luxury Double Bed',price:'345',period:'Month',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehend erit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'},
  ];
  // Popular Apartments
  featuresbg = 'assets/img/bg/06.jpg';

  popularapartment = [
    {img:'assets/img/room/01.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/02.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/03.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
  ];

  ngOnInit(): void {
  }

}
