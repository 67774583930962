<app-backtotop></app-backtotop>
<footer class="footer-two">
    <div class="footer-widget-area light-theme pt-100 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6 order-1">

                    <div class="widget contact-widget mb-50">
                        <div class="contact-lists">
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">Phone Number</h6>
                                    {{fern.contact.phone}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 order-3 order-lg-2">
                    <!-- Nav Widget -->
                 
                    <div class="widget contact-widget  mb-50">
                        <div class="contact-lists">
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-message"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">Email Address</h6>
                                    <a href="mailto:{{fern.contact.email}}">{{fern.contact.email}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 order-2 order-lg-3">
                    <!-- Contact Widget -->
                    <div class="widget contact-widget mb-50">

                        <div class="contact-lists">
                          
                            <div class="contact-box">
                                <div class="icon">
                                    <i class="flaticon-location-pin"></i>
                                </div>
                                <div class="desc">
                                    <h6 class="title">Address</h6>
                                    {{fern.contact.street}}, {{fern.contact.city}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area light-theme pt-30 pb-30">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5 order-2 order-md-1">
                    <p class="copyright-text copyright-two">Copyright By <a href="https://www.morrisdev.com/" target="_blank">Morris Development</a> - 2024</p>
                </div>
                <div class="col-lg-6 col-md-7 order-1 order-md-2">
                    <div class="footer-menu text-center text-md-right">
                        <!-- <ul>
                            <li><a routerLink="#">Terms of use</a></li>
                            <li><a routerLink="#">Privacy Environmental Policy</a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>