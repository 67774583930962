import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
 import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
 import { NavigationEnd, Router } from '@angular/router';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title,   private router: Router) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config','UA-71006624-2',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    }
    )
  }
  ngOnInit(): void {
     
  }
  private createTitle(routesCollection: any[]) {
    const title = 'Fern Corner';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }


}
