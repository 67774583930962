<div class="sidebar">
    <!-- About Author Widget -->
    <div class="widget about-author-widget mb-40">
        <h5 class="widget-title">About Me</h5>
        <div class="author-box">
            <img src="{{authorimg}}" alt="">
            <h6>Rosalina D. Willaimson</h6>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt ut labore.</p>
            <ul class="social-icon">
                <li><a routerLink="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a routerLink="#"><i class="fab fa-twitter"></i></a></li>
                <li><a routerLink="#"><i class="fab fa-behance"></i></a></li>
                <li><a routerLink="#"><i class="fab fa-linkedin"></i></a></li>
                <li><a routerLink="#"><i class="fab fa-youtube"></i></a></li>
            </ul>
        </div>
    </div>
    <!-- Search Widget -->
    <div class="widget search-widget mb-40">
        <h5 class="widget-title">Search Objects</h5>
        <form action="#">
            <input type="text" placeholder="Search your keyword...">
            <button type="submit"><i class="far fa-search"></i></button>
        </form>
    </div>
    <!-- Popular Post Widget -->
    <div class="widget popular-feeds mb-40">
        <h5 class="widget-title">Popular Feeds</h5>
        <div class="popular-feed-loop">
            <div class="single-popular-feed" *ngFor="let item of popularfeeds">
                <div class="feed-img">
                    <img src="{{item.img}}" alt="">
                </div>
                <div class="feed-desc">
                    <h6><a routerLink="/news-details">{{item.title}}</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> {{item.postdate}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Categories Widget -->
    <div class="widget categories-widget mb-40">
        <h5 class="widget-title">Categories</h5>
        <ul>
            <li *ngFor='let item of categories'>
                <a routerLink="/news-details">{{item.name}}<span *ngIf='item.number'>{{item.number}}</span></a>
            </li>
        </ul>
    </div>
    <!-- Social Icon Widget -->
    <div class="widget socail-widget mb-40">
        <h5 class="widget-title">Never Miss News</h5>
        <ul>
            <li><a routerLink="#"><i class="fab fa-facebook-f"></i></a></li>
            <li><a routerLink="#"><i class="fab fa-twitter"></i></a></li>
            <li><a routerLink="#"><i class="fab fa-behance"></i></a></li>
            <li><a routerLink="#"><i class="fab fa-linkedin"></i></a></li>
            <li><a routerLink="#"><i class="fab fa-pinterest"></i></a></li>
        </ul>
    </div>
    <!-- Twitter Feeds Widget -->
    <div class="widget twitter-feed-widget mb-40">
        <h5 class="widget-title">Twitter Feeds</h5>
        <div class="twitter-looop">
            <div class="single-twitter" *ngFor='let item of twitterfeeds'>
                <a routerLink="/news-details">
                    {{item.tweet}}
                </a>
                <span class="date">{{item.tweetdate}}</span>
            </div>
        </div>
    </div>
    <!-- Instagram Feeds Widget -->
    <div class="widget instagram-feed-widget mb-40">
        <h5 class="widget-title">Instagram Feeds</h5>
        <ul>
            <li *ngFor='let item of instaposts'><img src="{{item.img}}" alt=""></li>
        </ul>
    </div>
    <!-- Popular Tags Widget -->
    <div class="widget popular-tag-widget mb-40">
        <h5 class="widget-title">Popular Tags</h5>
        <ul>
            <li *ngFor='let item of tags'><a routerLink="/news-details">{{item.tagname}}</a></li>
        </ul>
    </div>
    <!-- Banner Ad Widget -->
    <div class="widget banner-ad-widget">
        <img src="{{advbanner}}" alt="">
    </div>
</div>