<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== Broker START ======-->
<section class="team-section has-slider pt-115 pb-115">
    <div class="container">
        <div class="section-title text-center mb-80">
            <span class="title-tag">Our Brokers</span>
            <h2>Talent We Rely On</h2>
        </div>
        <div class="row team-loop">
            <div class="col-lg-4 col-md-6" *ngFor='let item of teamblock'>
                <div class="member-box">
                    <div class="member-img">
                        <img src="{{item.img}}" alt="">
                    </div>
                    <div class="member-info">
                        <h3>{{item.name}}</h3>
                        <span>{{item.post}}</span>
                    </div>
                    <a routerLink="#" class="socail-trigger">+</a>
                </div>
            </div>
        </div>
        <div class="pagination-wrap">
            <app-pagination></app-pagination>
        </div>
    </div>
</section>
<!--====== Broker END ======-->
<app-footerinner></app-footerinner>