import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  // Logo
  logo = 'assets/img/footer-logo.png';

  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/light-v10';
  antialias: true;
  pitch: 45;

  constructor() { }

  ngOnInit(): void {
    // Map
   
    (mapboxgl as any).accessToken = environment.mapbox;
    
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 12,
      pitch: 45,
      antialias: true,
      center:[-122.92823900,  47.03603000]
    });
    // Add map controls

    var marker = new mapboxgl.Marker()
      .setLngLat([-122.92823900,  47.03603000])
      .addTo(this.map);
    this.map.on('load',  ()=> {
      // Insert the layer beneath any symbol layer.
      var layers = this.map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }
      this.map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
        labelLayerId
      );
    });
  }
}
