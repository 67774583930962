
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';

 


@Injectable({
  providedIn: 'root'
})
export class FernService {

  logo='assets/img/logo.png';

  // my token:  mapToken='pk.eyJ1IjoibW9ycmlzZGV2IiwiYSI6ImNraXBvY2JoZDFvcHoycnA3a2U3djI4Z2QifQ._0nof7TxESKmfirausFD0w';
  mapToken =  environment.mapbox;
  lonLat: [number, number] = [-122.92823900, 47.03603000]
  contact = {
    street: "1340 Fern Street SW",
    city: "Olympia",
    state: "WA",
    zip: "98502",
    phone: "(888) 737-4027",
    email: "info@ferncorner.com"
  }
  constructor(private _http: HttpClient, ) { }

  mailTo(to, subject, body) {

    //let url = `mailto:${encodeURIComponent(to)}?${subject ? encodeURIComponent(subject) : ''}&${body ? encodeURIComponent(body) : ''}`;


    var args = [];
    if (typeof subject !== 'undefined') {
      args.push('subject=' + encodeURIComponent(subject));
    }
    if (typeof body !== 'undefined') {
      args.push('body=' + encodeURIComponent(body))
    }

    var url = 'mailto:' + encodeURIComponent(to);
    if (args.length > 0) {
      url += '?' + args.join('&');
    }

    let w = window.open(url);
  }

  addToList(fname, lname ,email){
    let subscription = new Subscription();
    subscription.payload.firstName = fname;
    subscription.payload.lastName = lname;
    subscription.payload.email = email;

    return this._http.post(subscription.url,JSON.stringify(  subscription.payload) )

  }

  
  isEmail(search:string):boolean
  {
      var  serchfind:boolean;

      let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      serchfind = regexp.test(search);

      console.log(serchfind)
      return serchfind
  }
}

class Subscription{
    url='https://hooks.zapier.com/hooks/catch/492925/oc4k7ep'
    payload= {"firstName":"","lastName":"", "email":"" }
    
}