import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.css']
})
export class NewsdetailComponent implements OnInit {

  constructor() { }
  blogbanner = 'assets/img/blog-details/01.jpg';
  blogbanner2 = 'assets/img/blog-details/02.jpg';
  icon = 'assets/img/blog-details/icon.png';
  author = 'assets/img/blog-details/author.jpg';

  comment1 = 'assets/img/blog-details/avatar-1.jpg';
  comment2 = 'assets/img/blog-details/avatar-2.jpg';
  comment3 = 'assets/img/blog-details/avatar-3.jpg';
  // tags
  tagspost = [
    {tag:'Popular'},
    {tag:'desgin'},
    {tag:'ux'},
  ];
  // Social share
  socialshare = [
    {url:'#',icon:'fab fa-facebook-f'},
    {url:'#',icon:'fab fa-twitter'},
    {url:'#',icon:'fab fa-medium'},
    {url:'#',icon:'fab fa-google-plus-g'},
    {url:'#',icon:'fab fa-tumblr'},
  ];
  // Related post 
  relatedpost = [
    {img:'assets/img/blog-details/03.jpg',date:'24th March 2020',title:'A series of iOS 7 inspire vector icons sense.',text:'Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.'},
    {img:'assets/img/blog-details/04.jpg',date:'24th March 2020',title:'A series of iOS 7 inspire vector icons sense.',text:'Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.'},
  ];

  ngOnInit(): void {
  }

}
