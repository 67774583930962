import { Component, OnInit } from '@angular/core';
import { FernService } from 'src/app/services/fern.service';

@Component({
  selector: 'app-footerthree',
  templateUrl: './footerthree.component.html',
  styleUrls: ['./footerthree.component.css']
})
export class FooterthreeComponent implements OnInit {

  constructor(public fern:FernService) { }
  logo = 'assets/img/cropped-ico-1.png';
  ngOnInit(): void {
  }

}
