import { Component, AfterViewInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import $ from 'jquery';
import 'magnific-popup';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  // Banner
  bannerposts = [
    { img: 'assets/img/original/1340-Fern.jpg' },
    { img: 'assets/img/banner/02.jpg' },
  ];
  bannerConfig = { 
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "fade": true,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 4000,
    "arrows": false,
    "dots": false,
  }
  // Apartments
  apartmentspost = [
    { img: 'assets/img/room-suite/01.jpg', title: 'Plex Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
    { img: 'assets/img/room-suite/02.jpg', title: 'Luxury Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
    { img: 'assets/img/room-suite/03.jpg', title: 'Garden Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
    { img: 'assets/img/room-suite/04.jpg', title: 'Duplex Apartment', text: '1 Person, Queed Bed, 2 Windows', price: '345', period: 'Month' },
  ];
  // Condos
  condospost = [
    { img: 'assets/img/condos/menu-gallery-1.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
    { img: 'assets/img/condos/menu-gallery-2.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
    { img: 'assets/img/condos/menu-gallery-3.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
    { img: 'assets/img/condos/menu-gallery-4.jpg', title: 'City Hotel', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do', price: '300', btntext: 'Book now' },
  ];
  // Counter
  counterpost = [
    { icon: 'assets/img/icon/01.png', number: '84', prefix: 'k', title: 'Apartmentss in Album' },
    { icon: 'assets/img/icon/02.png', number: '10', prefix: 'M', title: 'Active Backers Around World' },
    { icon: 'assets/img/icon/03.png', number: '02', prefix: 'k', title: 'Categories Served' },
    { icon: 'assets/img/icon/04.png', number: '100', prefix: 'M', title: 'Ideas Raised Funds' },
  ];
  // Video
  videobg = 'assets/img/text-block/03.jpg';
  // Team
  teampost = [
    { img: 'assets/img/team/01.jpg', name: 'Rosa D.William', post: 'Founder & CEO' },
    { img: 'assets/img/team/02.jpg', name: 'hilixer b. browni', post: 'co-founder' },
    { img: 'assets/img/team/03.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/04.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/05.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/01.jpg', name: 'Rosa D.William', post: 'Founder & CEO' },
    { img: 'assets/img/team/02.jpg', name: 'hilixer b. browni', post: 'co-founder' },
    { img: 'assets/img/team/03.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/04.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
    { img: 'assets/img/team/05.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
  ];
  teamConfig = {
    infinite: true,
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  ngAfterViewInit(): void {
    // Counter
    ($('.counter') as any).each(function () {
      ($(this) as any).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function () {
          ($(this) as any).text(Math.ceil(this.Counter));
        },
      });
    });
    // Video popup
    ($('.popup-video') as any).magnificPopup({
      type: 'iframe',
    });
  }

}
