<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== ROOM-LIST START ======-->
<section class="room-section room-list-style pt-120 pb-120">
    <div class="container">
        <div class="room-list-loop">
            <div class="room-box mb-30" *ngFor='let item of appartmentlistpost'>
                <div class="row no-gutters justify-content-center">
                    <div class="col-lg-5 col-md-10">
                        <div class="room-img-wrap">
                            <div class="room-img" style="background-image: url({{item.img}});"></div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-10">
                        <div class="room-desc">
                            <div class="row align-items-center">
                                <div class="col-sm-12">
                                    <ul class="icons" style="float:right;margin-top:-20px" *ngIf="item.mapLink">
                                        <li><a href="{{item.mapLink}}" target="_blank">
                                            <i class="fal fa-compress-arrows-alt"></i>&nbsp;map</a></li>
                                        <li><a href="{{item.menuLink}}" target="_blank">
                                             <i class="fal fa-utensils"></i>  &nbsp; menu</a></li>
                                    </ul>

                                    <h4 class="title"><a routerLink="/apartment-details">{{item.title}}</a></h4>
                                    <p  [innerHTML]="item.text">
                                   
                                    </p>
                                </div>

                            </div>
                            <hr>
                            <div class="row align-items-center">
                                <div class="col-sm-6">
                                    <div class="text-sm-center">
                                        <a href="tel:{{item.phone}}">{{item.phone}} </a>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="text-sm-center">
                                        <a href="{{item.website}}" target="_blank">{{item.website}}</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="pagination-wrap">
            <app-pagination></app-pagination>
        </div> -->
    </div>
</section>
<!--====== ROOM-LIST END ======-->
<app-footerthree></app-footerthree>