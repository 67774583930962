import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
  import { NgwWowModule } from 'ngx-wow';

import { NiceSelectModule } from "ng-nice-select";
//import { CountUpModule } from 'countup.js-angular2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HometwoComponent } from './components/pages/hometwo/hometwo.component';
import { HomethreeComponent } from './components/pages/homethree/homethree.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ApartmentdetailsComponent } from './components/pages/apartmentdetails/apartmentdetails.component';
import { ApartmentgridComponent } from './components/pages/apartmentgrid/apartmentgrid.component';
import { ApartmentlistComponent } from './components/pages/apartmentlist/apartmentlist.component';
import { BrokerComponent } from './components/pages/broker/broker.component';
import { ClassificationComponent } from './components/pages/classification/classification.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsdetailComponent } from './components/pages/newsdetail/newsdetail.component';
import { PlacesComponent } from './components/pages/places/places.component';
import { PlacesdetailComponent } from './components/pages/placesdetail/placesdetail.component';
import { TrendingComponent } from './components/pages/trending/trending.component';


import { HeaderComponent } from './components/layouts/header/header.component';
import { HeadertwoComponent } from './components/layouts/headertwo/headertwo.component';
import { HeaderthreeComponent } from './components/layouts/headerthree/headerthree.component';
import { HeaderfourComponent } from './components/layouts/headerfour/headerfour.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { FootertwoComponent } from './components/layouts/footertwo/footertwo.component';
import { FooterthreeComponent } from './components/layouts/footerthree/footerthree.component';
import { FooterinnerComponent } from './components/layouts/footerinner/footerinner.component';
import { BlogsidebarComponent } from './components/layouts/blogsidebar/blogsidebar.component';
import { BreadcrumbComponent } from './components/layouts/breadcrumb/breadcrumb.component';
import { CanvasComponent } from './components/layouts/canvas/canvas.component';
import { BacktotopComponent } from './components/layouts/backtotop/backtotop.component';
import { BlogpostComponent } from './components/layouts/blogpost/blogpost.component';
import { InstapostComponent } from './components/layouts/instapost/instapost.component';
import { HaboutComponent } from './components/layouts/habout/habout.component';
import { PaginationComponent } from './components/layouts/pagination/pagination.component';
import { FernService } from './services/fern.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HometwoComponent,
    HomethreeComponent,
    AboutComponent,
    ApartmentdetailsComponent,
    ApartmentgridComponent,
    ApartmentlistComponent,
    BrokerComponent,
    ClassificationComponent,
    ContactComponent,
    GalleryComponent,
    NewsComponent,
    NewsdetailComponent,
    PlacesComponent,
    PlacesdetailComponent,
    TrendingComponent,
    HeaderComponent,
    HeadertwoComponent,
    HeaderthreeComponent,
    HeaderfourComponent,
    PreloaderComponent,
    FooterComponent,
    FootertwoComponent,
    FooterthreeComponent,
    FooterinnerComponent,
    BlogsidebarComponent,
    BreadcrumbComponent,
    CanvasComponent,
    BacktotopComponent,
    BlogpostComponent,
    InstapostComponent,
    HaboutComponent,
    PaginationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
   // NiceSelectModule,
   // BreadcrumbModule,
   //NgwWowModule,
   // CountUpModule,
    NgbModule,
    HttpClientModule
  ],
  providers: [FernService],
  bootstrap: [AppComponent]
})
export class AppModule {

}
