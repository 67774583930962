import { Component, AfterViewInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import $ from 'jquery';
import 'magnific-popup';
 
@Component({
  selector: 'app-hometwo',
  templateUrl: './hometwo.component.html',
  styleUrls: ['./hometwo.component.css']
})
export class HometwoComponent implements AfterViewInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  // Banner
  bannerpost = [
    { img: 'assets/img/original/1340-Fern-3.jpg', tag: 'The perfect home away from home', title: 'The Perfect Home For You', urltext1: 'take a tour', urltext2: 'Learn More' },
    { img: 'assets/img/banner/05.jpg', tag: 'The ultimate luxury experience', title: 'The Perfect Home For You', urltext1: 'take a tour', urltext2: 'Learn More' },
  ];
  bannerConfig = {
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 5000,
    "dots": false,
    "fade": true,
    "arrows": false,
  }
  // facilities
  facilitiespost = [
    { img: 'assets/img/we-offer/01.jpg', icon: 'flaticon-team', title: 'Strong Team', text: 'You have a family or Party Reunion' },
    { img: 'assets/img/we-offer/02.jpg', icon: 'flaticon-fast-food', title: 'Fresh Food', text: 'You have a family or Party Reunion' },
    { img: 'assets/img/we-offer/03.jpg', icon: 'flaticon-swimming', title: 'Swimming Pool', text: 'You have a family or Party Reunion' },
    { img: 'assets/img/we-offer/04.jpg', icon: 'flaticon-hotel', title: 'Luxury Room', text: 'You have a family or Party Reunion' },
  ];
  // Video
  videoimg = 'assets/img/bg/05.jpg';
  videoicon = 'assets/img/icon/07.png';
  // Roomtype
  room1 = 'assets/img/room-type/01.jpg';
  room2 = 'assets/img/room-type/02.jpg';
  room3 = 'assets/img/room-type/03.jpg';
  room4 = 'assets/img/room-type/04.jpg';
  // Core Features
  feature1 = 'assets/img/feature/01.jpg';
  feature2 = 'assets/img/feature/02.jpg';
  feature3 = 'assets/img/feature/03.jpg';

  // Room post
  roomslideronepost = [
    { img: 'assets/img/room-slider/01.jpg' },
    { img: 'assets/img/room-slider/02.jpg' },
    { img: 'assets/img/room-slider/03.jpg' },
    { img: 'assets/img/room-slider/04.jpg' },
    { img: 'assets/img/room-slider/05.jpg' },
  ];
  roomslideroneConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "fade": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 4000,
    "arrows": false,
    "dots": false,
    "centerMode": true,
    "centerPadding": '6%',
    "responsive": [
      {
        "breakpoint": 1600,
        "settings": {
          "slidesToShow": 2,
        },
      },
      {
        "breakpoint": 992,
        "settings": {
          "slidesToShow": 1,
          "centerPadding": '15%',
        },
      },
    ],
  };
  roomslidertwopost = [
    {icon:'flaticon-hotel',title:'Luxury Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'},
    {icon:'flaticon-hotel',title:'Luxury Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'},
    {icon:'flaticon-hotel',title:'Luxury Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'},
    {icon:'flaticon-hotel',title:'Luxury Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'},
    {icon:'flaticon-hotel',title:'Luxury Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'},
  ];
  roomslidercontentConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "fade": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 4000,
    "arrows": false,
    "dots": true,
  }


  ngAfterViewInit(): void {
    // Video popup
    ($('.popup-video') as any).magnificPopup({
      type: 'iframe',
    });
  }

}
