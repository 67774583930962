<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== BLOG SECTION START ======-->
<section class="blog-section pt-120 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="blog-loop">
                    <div class="post-box mb-40">
                        <div class="post-media">
                            <img src="{{blogimg}}" alt="">
                        </div>
                        <div class="post-desc">
                            <a routerLink='/news-details' class="cat">Businese</a>
                            <h2>
                                <a routerLink='/news-details'>Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod
                                    tempor.</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>

                            <div class="post-footer">
                                <div class="author">
                                    <a routerLink='/news-details'>
                                        <img src="{{smallauthor}}" alt="">
                                        by Hetmayar
                                    </a>
                                </div>
                                <div class="read-more">
                                    <a routerLink='/news-details'><i class="far fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="post-box with-video mb-40">
                        <div class="post-media">
                            <img src="{{blogimg2}}" alt="">
                            <a routerLink='/news-details' class="play-icon"><i class="fas fa-play"></i></a>
                        </div>
                        <div class="post-desc">
                            <a routerLink='/news-details' class="cat">Businese</a>
                            <h2>
                                <a routerLink='/news-details'>Adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore.</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>

                            <div class="post-footer">
                                <div class="author">
                                    <a routerLink='/news-details'>
                                        <img src="{{smallauthor}}" alt="">
                                        by Hetmayar
                                    </a>
                                </div>
                                <div class="read-more">
                                    <a routerLink='/news-details'><i class="far fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="post-box mb-40">
                        <div class="post-media">
                            <img src="{{blogimg3}}" alt="">
                        </div>
                        <div class="post-desc">
                            <a routerLink='/news-details' class="cat">Businese</a>
                            <h2>
                                <a routerLink='/news-details'>Magna aliqua. Ut enim ad minim venia
                                    m, quis nostrud exercitation ullamco</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>

                            <div class="post-footer">
                                <div class="author">
                                    <a routerLink='/news-details'>
                                        <img src="{{smallauthor}}" alt="Image">
                                        by Hetmayar
                                    </a>
                                </div>
                                <div class="read-more">
                                    <a routerLink='/news-details'><i class="far fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="post-box sound-cloud-post mb-40">
                        <div class="post-media">
                            <img src="{{blogimg4}}" alt="">
                        </div>
                        <div class="post-desc">
                            <a routerLink='/news-details' class="cat">Businese</a>
                            <h2>
                                <a routerLink='/news-details'>Laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor.</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>

                            <div class="post-footer">
                                <div class="author">
                                    <a routerLink='/news-details'>
                                        <img src="{{smallauthor}}" alt="">
                                        by Hetmayar
                                    </a>
                                </div>
                                <div class="read-more">
                                    <a routerLink='/news-details'><i class="far fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="post-box no-thumb mb-40">
                        <div class="post-desc">
                            <a routerLink='/news-details' class="cat">Businese</a>
                            <h2>
                                <a routerLink='/news-details'>In reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat.</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>

                            <div class="post-footer">
                                <div class="author">
                                    <a routerLink='/news-details'>
                                        <img src="{{smallauthor}}" alt="">
                                        by Hetmayar
                                    </a>
                                </div>
                                <div class="read-more">
                                    <a routerLink='/news-details'><i class="far fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="post-box quote-post mb-40">
                        <div class="post-desc">
                            <h2>
                                <a routerLink='/news-details'>Excepteur sint occaecat cupida
                                    tat non proident, sunt in.</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="post-box title-with-thumb mb-40">
                        <div class="post-desc">
                            <a routerLink='/news-details' class="cat">Businese</a>
                            <h2>
                                <a routerLink='/news-details'>Culpa qui officia deserunt mollit anim
                                    id est laborum. Sed ut perspiciatis</a>
                            </h2>
                            <ul class="post-meta">
                                <li><a routerLink='/news-details'><i class="far fa-eye"></i>232 Views</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-comments"></i>35 Comments</a></li>
                                <li><a routerLink='/news-details'><i class="far fa-calendar-alt"></i>24th March 2020</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="pagination-wrap">
                    <app-pagination></app-pagination>
                </div>
            </div>
            <!-- Blog Sidebar -->
            <div class="col-lg-4 col-md-8 col-sm-10">
                <app-blogsidebar></app-blogsidebar>
            </div>
        </div>
    </div>
</section>
<!--====== BLOG SECTION END ======-->
<app-footerinner></app-footerinner>