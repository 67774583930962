import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ApartmentdetailsComponent } from './components/pages/apartmentdetails/apartmentdetails.component';
import { ApartmentgridComponent } from './components/pages/apartmentgrid/apartmentgrid.component';
import { ApartmentlistComponent } from './components/pages/apartmentlist/apartmentlist.component';
import { BrokerComponent } from './components/pages/broker/broker.component';
import { ClassificationComponent } from './components/pages/classification/classification.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomethreeComponent } from './components/pages/homethree/homethree.component';
import { HometwoComponent } from './components/pages/hometwo/hometwo.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsdetailComponent } from './components/pages/newsdetail/newsdetail.component';
import { PlacesComponent } from './components/pages/places/places.component';
import { PlacesdetailComponent } from './components/pages/placesdetail/placesdetail.component';
import { TrendingComponent } from './components/pages/trending/trending.component';

const routes: Routes = [
  // {path:'',component:HomeComponent, data: { breadcrumb: 'Homepage' }},
  {path:'',component:HomethreeComponent, data: { breadcrumb: 'Homepage' }},
  {path:'hometwo',component:HometwoComponent, data: { breadcrumb: 'Homepage' }},
  {path:'homethree',component:HomethreeComponent, data: { breadcrumb: 'Homepage' }},
  {path:'apartment-grid',component:ApartmentgridComponent, data: { breadcrumb: 'Apartment Grid' }},
  {path:'restaurants',component:ApartmentlistComponent, data: { breadcrumb: 'restaurants' }},
  {path:'apartment-details',component:ApartmentdetailsComponent, data: { breadcrumb: 'Apartment Details' }},
  {path:'trending',component:TrendingComponent, data: { breadcrumb: 'Trending' }},
  {path:'news',component:NewsComponent, data: { breadcrumb: 'News' }},
  {path:'news-details',component:NewsdetailComponent, data: { breadcrumb: 'News Details' }},
  {path:'places',component:PlacesComponent, data: { breadcrumb: 'Places' }},
  {path:'places-details',component:PlacesdetailComponent, data: { breadcrumb: 'Places Details' }},
  {path:'classification',component:ClassificationComponent, data: { breadcrumb: 'Classification' }},
  {path:'gallery',component:GalleryComponent, data: { breadcrumb: 'Gallery' }},
  {path:'contact',component:ContactComponent, data: { breadcrumb: 'Contact' }},
  {path:'about',component:AboutComponent, data: { breadcrumb: 'About' }},
  {path:'broker',component:BrokerComponent, data: { breadcrumb: 'Broker' }},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
