import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import $ from 'jquery';
import 'magnific-popup'; 

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  // Video
  videobg = 'assets/img/text-block/04.jpg';
  // features
  featurespost = [
    { icon: 'flaticon-coffee', title: 'breakfast', animation: '.3s' },
    { icon: 'flaticon-air-freight', title: 'Airport Pickup', animation: '.4s' },
    { icon: 'flaticon-marker', title: 'city guide', animation: '.5s' },
    { icon: 'flaticon-barbecue', title: 'bbq party', animation: '.6s' },
    { icon: 'flaticon-hotel', title: 'luxury room', animation: '.7s' },
  ];
  // About img
  aboutimg = 'assets/img/text-block/05.jpg';
  // Counter
  counterpost = [
    { icon: 'flaticon-user-1', number: '8000', prefix: '', title: 'Happy Users' },
    { icon: 'flaticon-like', number: '10', prefix: 'M', title: 'Reviews & Appriciate' },
    { icon: 'flaticon-suitcase', number: '100', prefix: '', title: 'Country Coverage' },
  ];
  // Gallery
  gallery1 = 'assets/img/gallery/09.jpg';
  gallery2 = 'assets/img/gallery/10.jpg';
  // Blog
  blogpost = [
    { img: 'assets/img/latest-post/01.jpg', title: 'Each of our 8 double rooms has its own distinct.', postdate: '28th Aug 2020', author: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
    { img: 'assets/img/latest-post/02.jpg', title: 'Each of our 8 double rooms has its own distinct.', postdate: '28th Aug 2020', author: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
    { img: 'assets/img/latest-post/03.jpg', title: 'Each of our 8 double rooms has its own distinct.', postdate: '28th Aug 2020', author: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
  ];
  ngOnInit(): void {
    // Counter
    ($('.counter') as any).each(function () {
      ($(this) as any).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function () {
          ($(this) as any).text(Math.ceil(this.Counter));
        },
      });
    });
    // Video popup
    ($('.popup-video') as any).magnificPopup({
      type: 'iframe',
    });
  }

}
