import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instapost',
  templateUrl: './instapost.component.html',
  styleUrls: ['./instapost.component.css']
})
export class InstapostComponent implements OnInit {

  constructor() { }
  // Instagram post
  instapost = [
    { img: 'assets/img/instagram/01.jpg' },
    { img: 'assets/img/instagram/02.jpg' },
    { img: 'assets/img/instagram/03.jpg' },
    { img: 'assets/img/instagram/04.jpg' },
    { img: 'assets/img/instagram/05.jpg' },
    { img: 'assets/img/instagram/06.jpg' },
    { img: 'assets/img/instagram/01.jpg' },
    { img: 'assets/img/instagram/02.jpg' },
    { img: 'assets/img/instagram/03.jpg' },
    { img: 'assets/img/instagram/04.jpg' },
    { img: 'assets/img/instagram/05.jpg' },
    { img: 'assets/img/instagram/06.jpg' },
  ];
  instasliderConfig = {
    slidesToShow: 6,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  ngOnInit(): void {
    
  }

}
