import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.css']
})
export class ClassificationComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  duplexpost = [
    {img:'assets/img/restu/01.jpg',category:'italian food',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/02.jpg',category:'italian food',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/03.jpg',category:'italian food',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/04.jpg',category:'italian food',title:'4X Pan Pijja',price:'90'},
  ];
  convertiblepost = [
    {img:'assets/img/restu/01.jpg',category:'chicken',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/02.jpg',category:'chicken',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/03.jpg',category:'chicken',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/04.jpg',category:'chicken',title:'4X Pan Pijja',price:'90'},
  ];
  gardenpost = [
    {img:'assets/img/restu/01.jpg',category:'coffee',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/02.jpg',category:'coffee',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/03.jpg',category:'coffee',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/04.jpg',category:'coffee',title:'4X Pan Pijja',price:'90'},
  ];
  loftpost = [
    {img:'assets/img/restu/01.jpg',category:'bake cake',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/02.jpg',category:'bake cake',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/03.jpg',category:'bake cake',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/04.jpg',category:'bake cake',title:'4X Pan Pijja',price:'90'},
  ];
  triplexpost = [
    {img:'assets/img/restu/01.jpg',category:'cokkies',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/02.jpg',category:'cokkies',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/03.jpg',category:'cokkies',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/04.jpg',category:'cokkies',title:'4X Pan Pijja',price:'90'},
  ];
  micropost = [
    {img:'assets/img/restu/01.jpg',category:'coctail',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/02.jpg',category:'coctail',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/03.jpg',category:'coctail',title:'4X Pan Pijja',price:'90'},
    {img:'assets/img/restu/04.jpg',category:'coctail',title:'4X Pan Pijja',price:'90'},
  ];
  
  // Menu left post 
  menuleftposts = [
    {img:"assets/img/menu/01.jpg",url:'#',title:'Eggs & Bacon',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.3s'},
    {img:"assets/img/menu/02.jpg",url:'#',title:'Tea or Coffee',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.4s'},
    {img:"assets/img/menu/03.jpg",url:'#',title:'Chia Oatmeal',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.5s'},
    {img:"assets/img/menu/04.jpg",url:'#',title:'Juice',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.6s'},
    {img:"assets/img/menu/05.jpg",url:'#',title:'Chia Oatmeal',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.7s'},
  ];
  // Menu right post 
  menurightposts = [
    {img:"assets/img/menu/06.jpg",url:'#',title:'Fruit Parfait',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.3s'},
    {img:"assets/img/menu/07.jpg",url:'#',title:'Marmalade Selection',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.4s'},
    {img:"assets/img/menu/08.jpg",url:'#',title:'Cheese Platen',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.5s'},
    {img:"assets/img/menu/09.jpg",url:'#',title:'Avocado Toast',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.6s'},
    {img:"assets/img/menu/10.jpg",url:'#',title:'Avocado Toast',desc:'Lorem ipsum dolor sit amet, consectetur adip isicing elit, sed do eiusmod tempor.',animation:'.7s'},
  ];
  ngOnInit(): void {
  }

}
