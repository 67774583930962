import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apartmentgrid',
  templateUrl: './apartmentgrid.component.html',
  styleUrls: ['./apartmentgrid.component.css']
})
export class ApartmentgridComponent implements OnInit {

  constructor() { }

  appartmentgridpost = [
    {img:'assets/img/room/01.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/02.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/03.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/04.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/05.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/06.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/07.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/08.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/09.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/10.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/11.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
    {img:'assets/img/room/12.jpg',title:'Minimal Duplex Apartment',text:'Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed do eiusmod tempor.',price:'345',period:'Month'},
  ];

  ngOnInit(): void {
  }

}
