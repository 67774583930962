import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  constructor() { }
  // Image
  breadcrumbimg = 'assets/img/banner/contact-banner.jpg';// 'assets/img/bg/04.jpg';

  ngOnInit(): void {
  }

}
