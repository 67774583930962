import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogsidebar',
  templateUrl: './blogsidebar.component.html',
  styleUrls: ['./blogsidebar.component.css']
})
export class BlogsidebarComponent implements OnInit {

  constructor() { }

  authorimg = 'assets/img/author.png';
  advbanner = 'assets/img/banner-widget.jpg';

  popularfeeds = [
    {img:'assets/img/recent-post-wid/04.png',title:'Lorem ipsum dolor sit cing elit, sed do.',postdate:'24th March 2020'},
    {img:'assets/img/recent-post-wid/05.png',title:'Lorem ipsum dolor sit cing elit, sed do.',postdate:'24th March 2020'},
    {img:'assets/img/recent-post-wid/06.png',title:'Lorem ipsum dolor sit cing elit, sed do.',postdate:'24th March 2020'},
  ];

  categories = [
    {name:'Business',number:'26'},
    {name:'Consultant',number:'26'},
    {name:'Creative',number:'26'},
    {name:'UI/UX',number:'26'},
    {name:'Technology',number:'26'},
  ];

  twitterfeeds = [
    {tweet:'Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @ ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1',tweetdate:'November 25, 2018'},
    {tweet:'Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @ ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1',tweetdate:'November 25, 2018'},
    {tweet:'Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @ ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1',tweetdate:'November 25, 2018'},
  ];

  instaposts = [
    {img:'assets/img/instagram-wid/01.jpg'},
    {img:'assets/img/instagram-wid/02.jpg'},
    {img:'assets/img/instagram-wid/03.jpg'},
    {img:'assets/img/instagram-wid/04.jpg'},
    {img:'assets/img/instagram-wid/05.jpg'},
    {img:'assets/img/instagram-wid/06.jpg'},
    {img:'assets/img/instagram-wid/07.jpg'},
    {img:'assets/img/instagram-wid/08.jpg'},
    {img:'assets/img/instagram-wid/09.jpg'},
  ];

  tags = [
    {tagname:'Popular'},
    {tagname:'design'},
    {tagname:'ux'},
    {tagname:'usability'},
    {tagname:'develop'},
    {tagname:'icon'},
    {tagname:'business'},
    {tagname:'consult'},
    {tagname:'kit'},
    {tagname:'keyboard'},
    {tagname:'mouse'},
    {tagname:'tech'},
  ];

  ngOnInit(): void {
  }

}
