<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== RESTAURANT PART START ======-->
<section class="restaurant-tab-area pt-120 pb-90">
    <div class="container">
        <ul ngbNav #nav="ngbNav" class="restaurant-rood-list row justify-content-center nav nav-pills mb-30" id="restaurant-tab"
            role="tablist">
            <li ngbNavItem class="col-lg-2 col-md-3 col-sm-4 col-6">
                <a ngbNavLink>
                    <i class="flaticon-duplex"></i>
                    <span class="title">Duplex</span>
                </a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of duplexpost'>
                            <div class="food-box">
                                <div class="thumb">
                                    <img src="{{item.img}}" alt="">
                                    <span class="price">${{item.price}}</span>
                                </div>
                                <div class="desc">
                                    <a routerLink="#" class="cat">{{item.category}}</a>
                                    <h4>{{item.title}}</h4>
                                    <a routerLink="#" class="link"><i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem class="col-lg-2 col-md-3 col-sm-4 col-6">
                <a ngbNavLink>
                    <i class="flaticon-medieval-house"></i>
                    <span class="title">Convertible</span>
                </a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of convertiblepost'>
                            <div class="food-box">
                                <div class="thumb">
                                    <img src="{{item.img}}" alt="">
                                    <span class="price">${{item.price}}</span>
                                </div>
                                <div class="desc">
                                    <a routerLink="#" class="cat">{{item.category}}</a>
                                    <h4>{{item.title}}</h4>
                                    <a routerLink="#" class="link"><i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem class="col-lg-2 col-md-3 col-sm-4 col-6">
                <a ngbNavLink>
                    <i class="flaticon-condominium"></i>
                    <span class="title">Garden View</span>
                </a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of gardenpost'>
                            <div class="food-box">
                                <div class="thumb">
                                    <img src="{{item.img}}" alt="">
                                    <span class="price">${{item.price}}</span>
                                </div>
                                <div class="desc">
                                    <a routerLink="#" class="cat">{{item.category}}</a>
                                    <h4>{{item.title}}</h4>
                                    <a routerLink="#" class="link"><i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem class="col-lg-2 col-md-3 col-sm-4 col-6">
                <a ngbNavLink>
                    <i class="flaticon-residential"></i>
                    <span class="title">Loft</span>
                </a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of loftpost'>
                            <div class="food-box">
                                <div class="thumb">
                                    <img src="{{item.img}}" alt="">
                                    <span class="price">${{item.price}}</span>
                                </div>
                                <div class="desc">
                                    <a routerLink="#" class="cat">{{item.category}}</a>
                                    <h4>{{item.title}}</h4>
                                    <a routerLink="#" class="link"><i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem class="col-lg-2 col-md-3 col-sm-4 col-6">
                <a ngbNavLink>
                    <i class="flaticon-building"></i>
                    <span class="title">Triplex</span>
                </a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of triplexpost'>
                            <div class="food-box">
                                <div class="thumb">
                                    <img src="{{item.img}}" alt="">
                                    <span class="price">${{item.price}}</span>
                                </div>
                                <div class="desc">
                                    <a routerLink="#" class="cat">{{item.category}}</a>
                                    <h4>{{item.title}}</h4>
                                    <a routerLink="#" class="link"><i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem class="col-lg-2 col-md-3 col-sm-4 col-6">
                <a ngbNavLink>
                    <i class="flaticon-apartment"></i>
                    <span class="title">Micro</span>
                </a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of micropost'>
                            <div class="food-box">
                                <div class="thumb">
                                    <img src="{{item.img}}" alt="">
                                    <span class="price">${{item.price}}</span>
                                </div>
                                <div class="desc">
                                    <a routerLink="#" class="cat">{{item.category}}</a>
                                    <h4>{{item.title}}</h4>
                                    <a routerLink="#" class="link"><i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <!-- tab content -->
        <div class="tab-content" id="restaurant-tabContent">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</section>
<!--====== RESTAURANT PART END ======-->

<!--====== MENU AREA START ======-->
<section class="menu-area gradient-white pt-115 pb-115">
    <div class="container">
        <!-- Section Title -->
        <div class="section-title text-center mb-80">
            <span class="title-tag">available</span>
            <h2>Treanding Menu</h2>
        </div>
        <!-- Menu Looop -->
        <div class="menu-loop">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="single-menu-box wow fadeInUp" data-wow-delay="{{ item.animation }}"
                        *ngFor="let item of menuleftposts">
                        <div class="menu-img" style="background-image: url('{{ item.img }}');">
                        </div>
                        <div class="menu-desc">
                            <h4><a href="{{ item.url }}">{{ item.title }}</a></h4>
                            <p>{{ item.desc }}
                            </p>
                            <a href="{{ item.url }}" class="menu-link"><i class="fal fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-10">
                    <div class="single-menu-box wow fadeInUp" data-wow-delay="{{ item.animation }}"
                        *ngFor="let item of menuleftposts">
                        <div class="menu-img" style="background-image: url('{{ item.img }}');">
                        </div>
                        <div class="menu-desc">
                            <h4><a href="{{ item.url }}">{{ item.title }}</a></h4>
                            <p>{{ item.desc }}
                            </p>
                            <a href="{{ item.url }}" class="menu-link"><i class="fal fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== MENU AREA END ======-->
<app-footerinner></app-footerinner>