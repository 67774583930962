<app-header></app-header>
<!--====== BANNER PART START ======-->
<section class="banner-area banner-style-one">
    <div class="container container-custom-two">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="banner-content">
                    <span class="promo-tag wow fadeInDown" data-wow-delay=".3s">Home away from home</span>
                    <h1 class="title wow fadeInLeft" data-wow-delay=".5s"> Fern <br>Corner
                    </h1>
                    <ul>
                        <li>
                            <a class="main-btn btn-filled wow fadeInUp" data-wow-delay=".7s" routerLink="#">take a
                                tour</a>
                        </li>
                        <li>
                            <a class="main-btn btn-border wow fadeInUp" data-wow-delay=".9s" routerLink="#">Learn
                                More</a>
                        </li>
                    </ul>
                </div>
            </div> 
            <div class="col-lg-6 col-md-6 wow fadeInRight" data-wow-delay="0.5s">
                <div class="banner-thumb d-none d-md-block">
                    <ngx-slick-carousel class="hero-slider-one" #slickModal="slick-carousel" [config]="bannerConfig">
                        <div ngxSlickItem class="single-thumb" *ngFor='let item of bannerposts'>
                            <img src="{{item.img}}" alt="images">
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== BANNER PART ENDS ======-->
<!--====== BOOKING FORM START ======-->
<div class="booking-form boxed-layout">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10 col-11">
                <div class="booking-form-inner">
                    <form action="#">
                        <div class="row align-items-end">
                            <div class="col-lg-3 col-md-6">
                                <div class="inputs-filed mt-30">
                                    <label for="arrival-date">Arrival Date</label>
                                    <div class="icon"><i class="fal fa-calendar-alt"></i></div>
                                    <input type="text" placeholder="24th march 2020" name="arrival-date" id="arrival-date">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="inputs-filed mt-30">
                                    <label for="departure-date">Departure Date</label>
                                    <div class="icon"><i class="fal fa-calendar-alt"></i></div>
                                    <input type="text" placeholder="30th march 2020" name="departure-date" id="departure-date">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="inputs-filed mt-30">
                                    <label for="guests">Guests</label>
                                    <select name="guests" id="guests" nice-select>
                                        <option value="" disabled selected>Select From Here</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="4">4</option>
                                        <option value="8">8</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="inputs-filed mt-30">
                                    <button type="submit">check availability</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== BOOKING FORM EN ======-->
<!--====== ABOUT PART START ======-->
<app-habout></app-habout>
<!--====== ABOUT PART END ======-->
<!--====== Our Apartments START ======-->
<section class="pt-115 pb-115 bg-white">
    <div class="container">
        <div class="section-title text-center mb-30">
            <span class="title-tag"> facilities </span>
            <h2>Apartments & Condos</h2>
        </div>
        <div class="text-center mb-20">
            <a routerLink="#">View more
                <i class="fal fa-arrow-right ml-2"></i>
            </a>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6" *ngFor='let item of apartmentspost'>
                <div class="apartment-box">
                    <div class="image-box">
                        <a routerLink="/apartment-details" class="d-block">
                            <img src="{{item.img}}" alt="img">
                        </a>
                    </div>
                    <div class="content-box-2">
                        <h3 class="title">
                            <a routerLink="/apartment-details">{{item.title}}</a>
                        </h3>
                        <p>{{item.text}}</p>
                        <span class="price">${{item.price}}/{{item.period}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== Our Apartments End ======-->
<!--====== Condos Overlay START ======-->
<section class="condos-overlay-sec">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-3 col-md-6" *ngFor='let item of condospost'>
                <div class="condo-item" style="background-image: url({{item.img}});">
                    <div class="title">
                        <i class="fal fa-plus text-white"></i>
                        <h4 class="text-white">{{item.title}}</h4>
                    </div>
                    <div class="title title-hidden">
                        <h4 class="text-white mt-0">{{item.title}}</h4>
                        <p class="text-white">{{item.text}}</p>
                        <div class="book-btn">
                            <a routerLink="#"><i class="fal fa-long-arrow-right"></i>
                                {{item.btntext}}
                            </a>
                        </div>
                    </div>
                    <a routerLink="#" class="main-btn btn-border">Starting at ${{item.price}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== Condos Overlay END ======-->
<!--====== CORE FEATURES START ======-->
<section class="core-feature-section bg-white pt-115 pb-115">
    <div class="container">
        <div class="section-title text-center mb-50">
            <span class="title-tag"> facilities </span>
            <h2>Core Features</h2>
        </div>
        <!-- Featre Loop -->
        <div class="row features-loop">
            <div class="col-lg-4 col-sm-6 order-1">
                <div class="feature-box wow fadeInLeft" data-wow-delay=".3s">
                    <div class="icon">
                        <i class="flaticon-rating"></i>
                    </div>
                    <h3><a routerLink="#">Have High Rating</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">01</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-2">
                <div class="feature-box wow fadeInDown" data-wow-delay=".4s">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3><a routerLink="#">Quiet Hours</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">02</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
                <div class="feature-box wow fadeInRight" data-wow-delay=".5s">
                    <div class="icon">
                        <i class="flaticon-location-pin"></i>
                    </div>
                    <h3><a routerLink="#">Best Locations</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">03</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
                <div class="feature-box wow fadeInLeft" data-wow-delay=".6s">
                    <div class="icon">
                        <i class="flaticon-clock-1"></i>
                    </div>
                    <h3><a routerLink="#">Free Cancellation</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">04</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-5">
                <div class="feature-box wow fadeInUp" data-wow-delay=".7s">
                    <div class="icon">
                        <i class="flaticon-credit-card"></i>
                    </div>
                    <h3><a routerLink="#">Payment Options</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">05</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-6">
                <div class="feature-box wow fadeInRight" data-wow-delay=".8s">
                    <div class="icon">
                        <i class="flaticon-discount"></i>
                    </div>
                    <h3><a routerLink="#">Special Offers</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">06</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== CORE FEATURES END ======-->
<!--====== COUNTER UP START ======-->
<section class="counter-section bg-black">
    <div class="container">
        <div class="counter-inner pt-100 pb-60">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-11">
                    <div class="row">
                        <div class="col-lg-3 col-6" *ngFor='let item of counterpost'>
                            <div class="counter-box box-layout d-flex justify-content-center">
                                <div>
                                    <div class="icon">
                                        <img src="{{item.icon}}" alt="">
                                    </div>
                                    <h4><span class="counter">{{item.number}}</span>{{item.prefix}}<span class="plus-icon">+</span></h4>
                                    <span class="title">{{item.title}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== COUNTER UP END ======-->
<!--====== TEXT BLOCK START ======-->
<section class="text-block bg-black with-pattern pt-115 pb-115">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                <div class="block-text">
                    <div class="section-title mb-20">
                        <span class="title-tag">intro video</span>
                        <h2>Meet With Our
                            Luxury Place.</h2>
                    </div>
                    <p class="pr-50">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat you have to understand this.
                    </p>
                    <a routerLink="#" class="main-btn btn-filled mt-40">Book now</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-10 order-1 order-lg-2 wow fadeInRight" data-wow-delay=".3s">
                <div class="video-wrap video-wrap-two mb-small" style="background-image: url({{videobg}});">
                    <a href="http://www.youtube.com/embed/watch?v=EEJFMdfraVY" class="popup-video"><i class="fas fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="pattern-wrap">
        <div class="pattern"></div>
    </div>
</section>
<!--====== TEXT BLOCK END ======-->
<!--====== TEAM START ======-->
<section class="team-section bg-white has-slider pt-115 pb-85">
    <div class="container-fluid">
        <ngx-slick-carousel class="row team-loop team-slider-one" #slickModal="slick-carousel"
        [config]="teamConfig">
            <div ngxSlickItem class="col-lg-12" *ngFor='let item of teampost'>
                <div class="member-box">
                    <div class="member-img">
                        <img src="{{item.img}}" alt="">
                    </div>
                    <div class="member-info">
                        <h3>{{item.name}}</h3>
                        <span>{{item.post}}</span>
                    </div>
                    <a routerLink="#" class="socail-trigger">+</a>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</section>
<!--====== TEAM END ======-->
<!--====== LATEST NEWS START ======-->
<app-blogpost></app-blogpost>
<!--====== LATEST NEWS END ======-->
<app-footer></app-footer>