 

<!-- <div class="main-menu-area sticky-header"  >
    <h2>header-top-info</h2>

    ns1353.websitewelcome.com
    ns1354.websitewelcome.com


</div> -->

<header class="header-three"  >
    <div class="header-top"  style="display:none ">
        <div class="container container-custom-three" style="display:none ">
            <div class="d-md-flex align-items-center justify-content-between">
                <p class="welcome-text">A home away from home</p>
                <ul class="header-top-info">
                    <li>
                        <i class="fal fa-Clock"> </i>
                        Mon - Sat 9AM - 5PM
                    </li>
                    <!-- <li>
                        <i class="fal fa-Clock"> </i>
                        Mon - Sat 9.00 - 18.00
                    </li> -->
                </ul>
            </div>
        </div>
    </div>

    <div class="main-menu-area sticky-header"   >
        <div class="container container-custom-three">
            <div class="nav-container d-flex align-items-center justify-content-between">
                <!-- Site Logo -->
                <div class="site-logo" style="display: none;">
                    <a routerLink="#"><img src="assets/img/logo.png" alt="Logo"></a>
                   
                </div>
                <div style="width:100%;font-size:16pt;text-align: center;font-weight: bold;">Fern Corner Has Been Reserved Until July 2024</div>
                <!-- Main Menu -->
                <div class="nav-menu d-lg-flex align-items-center justify-content-between" style="display:none">

                    <!-- Navbar Close Icon -->
                    <!-- <div class="navbar-close">
                        <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                    </div> -->

                    <!-- Mneu Items -->
                    <!-- <div class="menu-items">
                        <ul>
                            <li>
                                <a routerLink="#" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home</a>
                                <ul class="submenu">
                                    <li><a routerLink='/' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home One</a></li>
                                    <li><a routerLink='/hometwo' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                                    <li><a routerLink='/homethree' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                                </ul>
                            </li>

                            <li><a routerLink="#" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Pages</a>
                                <ul class="submenu">
                                    <li>
                                        <a routerLink='/places' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Places Near By</a>
                                        <ul class="submenu">
                                            <li><a routerLink='/places-details' routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Places Details</a></li>
                                        </ul>
                                    </li>
                                    <li><a routerLink='/classification' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Classification</a></li>
                                    <li><a routerLink='/gallery' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Our Gallery</a></li>
                                    <li><a routerLink='/contact' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                                    <li><a routerLink='/broker' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Brokers</a></li>

                                </ul>
                            </li>
                            <li><a routerLink='/about' routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                            <li>
                                <a routerLink='/apartment-grid' routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Apartments</a>
                                <ul class="submenu">
                                    <li><a routerLink='/apartment-details' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Apartments Details</a></li>
                                    <li><a routerLink='/trending' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Apartments List 2</a></li>
                                    <li><a routerLink='/apartment-grid' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Apartments Grid</a></li>
                                    <li><a routerLink='/apartment-list' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Apartments List</a></li>
                                </ul>
                            </li>
                            <li>
                                <a routerLink='/news' routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">News</a>
                                <ul class="submenu">
                                    <li><a routerLink='/news-details' routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">News Details</a></li>
                                </ul>
                            </li>

                        </ul>
                    </div> -->
                </div>
                <!-- navbar right content -->
                <div class="menu-right-buttons"  style="display:none">
                    <!-- Log in icon -->
                    <div class="login-btn">
                        <a routerLink="#" id="loginBtn"><i class="fal fa-user"></i></a>
                    </div>
                    <!-- search btton -->
                    <div class="search">
                        <a routerLink="#" class="search-icon" id="searchBtn">
                            <i class="fal fa-search open-icon"></i>
                            <i class="fal fa-times close-icon"></i>
                        </a>
                        <div class="search-form">
                            <form action="#">
                                <input type="text" placeholder="Search your keyword...">
                                <button type="submit"><i class="far fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                    <!-- Off canvas Toggle -->
                    <div class="toggle">
                        <a routerLink="#" id="offCanvasBtn" (click)="offCanvasBtn()"><i class="fal fa-bars"></i></a>
                    </div>
                    <!-- Navbar Toggler -->
                    <div class="navbar-toggler">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
 
<div class="offcanvas-wrapper" [ngClass]="opencanvas ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="opencanvas ? '' : 'show-overly'" (click)="offCanvasclose()"></div>
    <div class="offcanvas-widget">
        <a href="#" class="offcanvas-close" (click)="offCanvasclose()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>