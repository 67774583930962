import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import $ from 'jquery';

@Component({
  selector: 'app-headertwo',
  templateUrl: './headertwo.component.html',
  styleUrls: ['./headertwo.component.css']
})
export class HeadertwoComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document) { }
  // Sticky Nav
  @HostListener('window:scroll', [])
  onWindowScroll(event: Event) {
    //set up the div "id=nav"
    if (document.body.scrollTop > 150 ||
      document.documentElement.scrollTop > 150) {
      document.getElementById('header').classList.add('sticky-active');
    }
    else {
      document.getElementById('header').classList.remove('sticky-active');
    }
  }

  // Canvas
  opencanvas: boolean = true;
  offCanvasBtn() {
    this.opencanvas = !this.opencanvas;
  }
  offCanvasclose() {
    this.opencanvas = !this.opencanvas;
  }
  ngOnInit(): void {
    // Variables
    var var_window = $(window),
      navContainer = $('.nav-container'),
      pushedWrap = $('.nav-pushed-item'),
      pushItem = $('.nav-push-item'),
      pushedHtml = pushItem.html(),
      pushBlank = '',
      navbarToggler = $('.navbar-toggler'),
      navMenu = $('.nav-menu'),
      navMenuLi = $('.nav-menu ul li'),
      closeIcon = $('.navbar-close');
    // navbar toggler
    navbarToggler.on('click', function () {
      navbarToggler.toggleClass('active');
      navMenu.toggleClass('menu-on');
    });

    // close icon
    closeIcon.on('click', function () {
      navMenu.removeClass('menu-on');
      navbarToggler.removeClass('active');
    });

    // adds toggle button to li items that have children
    navMenu.find('li a').each(function () {
      if ($(this).next().length > 0) {
        $(this)
          .parent('li')
          .append(
            '<span class="dd-trigger"><i class="fal fa-angle-down"></i></span>'
          );
      }
    });

    // expands the dropdown menu on each click
    navMenu.find('li .dd-trigger').on('click', function (e) {
      e.preventDefault();
      $(this)
        .parent('li')
        .children('ul')
        .stop(true, true)
        .slideToggle(350);
      $(this).parent('li').toggleClass('active');
    });

    function breakpointCheck() {
      var windoWidth = window.innerWidth;
      if (windoWidth <= 991) {
        navContainer.addClass('breakpoint-on');

        pushedWrap.html(pushedHtml);
        pushItem.hide();
      } else {
        navContainer.removeClass('breakpoint-on');

        pushedWrap.html(pushBlank);
        pushItem.show();
      }
    }

    breakpointCheck();
    var_window.on('resize', function () {
      breakpointCheck();
    });
  }

}
