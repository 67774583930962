import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-placesdetail',
  templateUrl: './placesdetail.component.html',
  styleUrls: ['./placesdetail.component.css']
})
export class PlacesdetailComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  mainbanner = 'assets/img/places/08.jpg';
  // Gallery
  gallerypost = [
    {img:'assets/img/places/09.jpg'},
    {img:'assets/img/places/10.jpg'},
  ];
  // placeposts
  placeposts = [
    { img: "assets/img/places/01.jpg", title: 'Duplex Restaurant', time: '1,500m | 21 min. Walk' },
    { img: "assets/img/places/02.jpg", title: 'Duplex Restaurant', time: '1,500m | 21 min. Walk' },
    { img: "assets/img/places/03.jpg", title: 'Duplex Restaurant', time: '1,500m | 21 min. Walk' },
    { img: "assets/img/places/04.jpg", title: 'Duplex Restaurant', time: '1,500m | 21 min. Walk' }
  ];
  ngOnInit(): void {
  }

}
