<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== GALLERY START ======-->
<div class="gallery-wrappper pt-120 pb-120">
    <div class="container">
        <div class="gallery-loop">
            <div class="single-gallery-image wow fadeInUp" data-wow-delay="1.1s" *ngFor='let item of gallerypost'>
                <a href="{{item.img}}" class="popup-image">
                    <img src="{{item.img}}" alt="">
                </a>
            </div>
        </div>
    </div>
</div>
<!--====== GALLERY END ======-->
<app-footerinner></app-footerinner>