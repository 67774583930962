<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== PLACES CONTENT START ======-->
<section class="places-wrapper pt-120 pb-120">
    <div class="container">
        <!-- Places Maps -->
        <div class="contact-maps bg-white" id="map"></div>

        <!-- Places Boxes -->
        <div class="places-boxes pt-115">
            <!-- Title -->
            <div class="section-title text-center mb-50">
                <span class="title-tag">Popular</span>
                <h2>Highlights Places</h2>
            </div>
            <div class="row justify-content-center">
                <div *ngFor="let item of placeposts" class="col-lg-3 col-md-4 col-sm-6 col-10">
                    <div class="place-box mt-30">
                        <div class="place-bg-wrap">
                            <div class="place-bg" style="background-image: url('{{ item.img }}');"></div>
                        </div>
                        <div class="desc">
                            <h4><a routerLink='/places-details'>{{ item.title }}</a></h4>
                            <span class="time">{{ item.time }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== PLACES CONTENT END ======-->
<app-footerinner></app-footerinner>