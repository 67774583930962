import { Component, OnInit } from '@angular/core';
import { FernService } from 'src/app/services/fern.service';

@Component({
  selector: 'app-footerinner',
  templateUrl: './footerinner.component.html',
  styleUrls: ['./footerinner.component.css']
})
export class FooterinnerComponent implements OnInit {

  constructor(private fern:FernService) { }
  logo;
  ngOnInit(): void {
    this.logo= this.fern.logo
  }

}
