 

<!-- <div class="main-menu-area sticky-header"  >
    <h2>header-top-info</h2>

    ns1353.websitewelcome.com
    ns1354.websitewelcome.com


</div> -->

<header class="header-three"  >
    <div class="header-top"  style="display:none ">
        <div class="container container-custom-three" style="display:none ">
            <div class="d-md-flex align-items-center justify-content-between">
                <p class="welcome-text">A home away from home</p>
                <ul class="header-top-info">
                    <li>
                        <i class="fal fa-Clock"> </i>
                        Mon - Sat 9AM - 5PM
                    </li>
                    <!-- <li>
                        <i class="fal fa-Clock"> </i>
                        Mon - Sat 9.00 - 18.00
                    </li> -->
                </ul>
            </div>
        </div>
    </div>

    <div class="main-menu-area sticky-header"   >
        <div class="container container-custom-three">
            <div class="nav-container d-flex align-items-center justify-content-between">
                <!-- Site Logo -->
                <div class="site-logo" style="display: none;">
                    <a routerLink="#"><img src="assets/img/logo.png" alt="Logo"></a>
                   
                </div>
                <div style="width:100%;font-size:16pt;text-align: center;font-weight: bold;">Fern Corner Has Been Reserved Until May 2025</div>
                <!-- Main Menu -->
                <div class="nav-menu d-lg-flex align-items-center justify-content-between" style="display:none">

                   
                </div>
                <!-- navbar right content -->
                <div class="menu-right-buttons"  style="display:none">
                    <!-- Log in icon -->
                    <div class="login-btn">
                        <a routerLink="#" id="loginBtn"><i class="fal fa-user"></i></a>
                    </div>
                    <!-- search btton -->
                    <div class="search">
                        <a routerLink="#" class="search-icon" id="searchBtn">
                            <i class="fal fa-search open-icon"></i>
                            <i class="fal fa-times close-icon"></i>
                        </a>
                        <div class="search-form">
                            <form action="#">
                                <input type="text" placeholder="Search your keyword...">
                                <button type="submit"><i class="far fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                    <!-- Off canvas Toggle -->
                    <div class="toggle">
                        <a routerLink="#" id="offCanvasBtn" (click)="offCanvasBtn()"><i class="fal fa-bars"></i></a>
                    </div>
                    <!-- Navbar Toggler -->
                    <div class="navbar-toggler">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
 
<div class="offcanvas-wrapper" [ngClass]="opencanvas ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="opencanvas ? '' : 'show-overly'" (click)="offCanvasclose()"></div>
    <div class="offcanvas-widget">
        <a href="#" class="offcanvas-close" (click)="offCanvasclose()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>