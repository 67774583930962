<section class="about-section pt-115 pb-115">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-10 wow fadeInLeft" data-wow-delay=".3s">
                <div class="row about-features-boxes fetaure-masonary">
                    <div class="col-sm-6">
                        <div class="single-feature-box">
                            <div class="icon">
                                <i class="flaticon-team"></i>
                            </div>
                            <h4><a routerLink="#">Strong Team</a></h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="single-feature-box only-bg mt-30" style="background-image: url({{singlefeature}});">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="single-feature-box only-bg mt-30" style="background-image: url({{singlefeature1}});">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="single-feature-box dark mt-30">
                            <div class="icon">
                                <i class="flaticon-hotel"></i>
                            </div>
                            <h4><a routerLink="#">Luxury Apartment</a></h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8 col-sm-10 wow fadeInRight" data-wow-delay=".3s">
                <div class="abour-text pl-50 pr-50">
                    <div class="section-title mb-30">
                        <span class="title-tag">about us</span>
                        <h2>Discover Our Apartments.</h2>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                    <a routerLink="#" class="main-btn btn-filled mt-40"> Book Now</a>
                </div>
            </div>
        </div>
    </div>
    <div class="about-right-bottom">
        <div class="about-bottom-img">
            <img src="{{bottomimg}}" alt="">
        </div>
    </div>
</section>