<app-headerthree></app-headerthree>
<!--====== BANNER PART START ======-->
<ngx-slick-carousel class="banner-area banner-style-three" id="bannerSlider" #slickModal="slick-carousel"
    [config]="bannerConfig">
    <div ngxSlickItem class="single-banner d-flex align-items-center justify-content-center"
        *ngFor='let item of bannerpost'>
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="banner-content">
                        <span class="promo-tag" data-animation="fadeInDown" data-delay=".6s">
                            {{item.tag}}
                        </span>
                        <h1 class="title" data-animation="fadeInLeft" data-delay=".9s">
                            {{item.title}}
                        </h1>
                        <ul *ngIf="item.urltext1">
                            <li data-animation="fadeInUp" data-delay="1.1s">
                                <a class="main-btn btn-filled" routerLink="/contact">{{item.urltext1}}</a>
                            </li>
                            <li data-animation="fadeInUp" data-delay="1.3s">
                                <a class="main-btn btn-border" routerLink="/about">{{item.urltext2}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- banner bg -->
        <div class="banner-bg" style="background-image: url({{item.img}});"></div>
        <div class="banner-overly"></div>
    </div>
</ngx-slick-carousel>
<!--====== BANNER PART ENDS ======-->




<!--====== BOOKING FORM START ======-->
<div class="booking-form booking-form-dark" style="display: none;">
    <div class="container">
        <div class="booking-form-inner">
            <form action="#">
                <div class="row align-items-end">
                    <div class="col-lg-3 col-sm-6">
                        <div class="inputs-filed mt-30">
                            <label for="arrival-date">Arrival Date</label>
                            <div class="icon"><i class="fal fa-calendar-alt"></i></div>
                            <input type="text" placeholder="24th march 2020" name="arrival-date" id="arrival-date"
                                #bookingStart>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="inputs-filed mt-30">
                            <label for="departure-date">Departure Date</label>
                            <div class="icon"><i class="fal fa-calendar-alt"></i></div>
                            <input type="text" placeholder="30th march 2020" name="departure-date" id="departure-date"
                                #bookingEnd>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="inputs-filed mt-30">
                            <label for="guests">Rooms Needed</label>
                            <select name="guests" id="guests" nice-select #roomCount>
                                <option value="" disabled selected>Select From Here</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="4">4</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="inputs-filed mt-30">
                            <button type="button" (click)="checkAvailability()">check availability</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>



<!--====== BOOKING FORM END ======-->
<!--====== ABOUT SECTION START ======-->
<!-- <app-habout></app-habout> -->
<!--====== ABOUT SECTION END ======-->
<!--====== ROOM SLIDER START ======-->
<section class="room-slider bg-white pb-100 pt-115">
    <div class="container-fluid p-0">
        <div class="section-title mb-80 text-center">
            <span class="title-tag">the home</span>
            <h2>Fully appointed and ready to move in</h2>
        </div>

        <ngx-slick-carousel class="row rooms-slider-two justify-content-center" #slickModal="slick-carousel"
            [config]='handpickapartmentSlider'>
            <div ngxSlickItem class="col-lg-12" *ngFor="let item of handpickapartmentpost">
                <div class="single-rooms-box">
                    <div class="room-img">
                        <div class="img" style="background-image: url({{item.img}});">
                        </div>
                    </div>
                    <!-- <ul class="icons">
                        <li><i class="fal fa-bed"></i></li>
                        <li><i class="fal fa-wifi"></i></li>
                        <li><i class="fal fa-car"></i></li>
                        <li><i class="fal fa-coffee"></i></li>
                        <li><i class="fal fa-concierge-bell"></i></li>
                        <li><i class="fal fa-swimmer"></i></li>
                        <li><i class="fal fa-compress-arrows-alt"></i></li>
                    </ul> -->
                    <div class="room-desc">
                        <div class="row align-items-center">
                            <div class="col-sm-8">
                                <h3><a routerLink="#">{{item.title}}</a></h3>
                                <p>
                                    {{item.text}}
                                </p>
                            </div>
                            <div class="col-sm-4">
                                <!-- <div class="price">
                                    ${{item.price}}<span>/{{item.period}}</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</section>
<!--====== ROOM SLIDER END ======-->




<!--====== ROOM SLIDER2 START ======-->
<section class="room-slider bg-white pb-100 pt-115">
    <div class="container-fluid p-0">
        <div class="section-title mb-80 text-center">
            <span class="title-tag">the bedrooms</span>
            <h2>Five Bedrooms, 3 upstairs and 2 downstairs</h2>
        </div>

        <ngx-slick-carousel class="row rooms-slider-two justify-content-center" #slickModal="slick-carousel"
            [config]='bedroomSlider'>
            <div ngxSlickItem class="col-lg-12" *ngFor="let item of bedrooms">
                <div class="single-rooms-box">
                    <div class="room-img">
                        <div class="img" style="background-image: url({{item.img}});">
                        </div>
                    </div>
                    <!-- <ul class="icons">
                        <li><i class="fal fa-bed"></i></li>
                        <li><i class="fal fa-wifi"></i></li>
                        <li><i class="fal fa-car"></i></li>
                        <li><i class="fal fa-coffee"></i></li>
                        <li><i class="fal fa-concierge-bell"></i></li>
                        <li><i class="fal fa-swimmer"></i></li>
                        <li><i class="fal fa-compress-arrows-alt"></i></li>
                    </ul> -->
                    <div class="room-desc">
                        <div class="row align-items-center">
                            <div class="col-sm-8">
                                <h3><a routerLink="#">{{item.title}}</a></h3>
                                <p>
                                    {{item.text}}
                                </p>
                            </div>
                            <div class="col-sm-4">
                                <!-- <div class="price">
                                    ${{item.price}}<span>/{{item.period}}</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</section>
<!--====== ROOM SLIDER2 END ======-->




<!--====== COUNTER UP START ======-->
<!-- <section class="counter-section bg-black pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-6" *ngFor='let item of counterpost'>
                <div class="counter-box">
                    <div class="icon">
                        <img src="{{item.icon}}" alt="">
                    </div>
                    <h4><span class="counter">{{item.number}}</span>{{item.prefix}}<span class="plus-icon">+</span></h4>
                    <span class="title">{{item.title}}</span>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="intro-video overlapped bg-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-9 col-sm-12">
                <div class="video-wrap video-wrap-two mb-small" style="background-image: url({{videobg}});">
                    <a href="http://www.youtube.com/embed/watch?v=EEJFMdfraVY" class="popup-video"><i
                            class="fas fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!--====== COUNTER UP END ======-->

<!--====== CORE FEATURES START ======-->
<!-- <section class="core-feature-section bg-white pt-115 pb-115">
    <div class="container">
        <div class="section-title text-center mb-50">
            <span class="title-tag"> neighborhood </span>
            <h2>Nearby all local ammenities</h2>
        </div>
         <div class="row features-loop">
            <div class="col-lg-4 col-sm-6 order-1">
                <div class="feature-box wow fadeInLeft" data-wow-delay=".3s">
                    <div class="icon">
                        <i class="flaticon-rating"></i>
                    </div>
                    <h3><a routerLink="#">Have High Rating</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">01</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-2">
                <div class="feature-box wow fadeInDown" data-wow-delay=".4s">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3><a routerLink="#">Quiet Hours</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">02</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
                <div class="feature-box wow fadeInRight" data-wow-delay=".5s">
                    <div class="icon">
                        <i class="flaticon-location-pin"></i>
                    </div>
                    <h3><a routerLink="#">Best Locations</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">03</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
                <div class="feature-box wow fadeInLeft" data-wow-delay=".6s">
                    <div class="icon">
                        <i class="flaticon-clock-1"></i>
                    </div>
                    <h3><a routerLink="#">Free Cancellation</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">04</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-5">
                <div class="feature-box wow fadeInUp" data-wow-delay=".7s">
                    <div class="icon">
                        <i class="flaticon-credit-card"></i>
                    </div>
                    <h3><a routerLink="#">Payment Options</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">05</span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-6">
                <div class="feature-box wow fadeInRight" data-wow-delay=".8s">
                    <div class="icon">
                        <i class="flaticon-discount"></i>
                    </div>
                    <h3><a routerLink="#">Special Offers</a></h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna..
                    </p>
                    <span class="count">06</span>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--====== CORE FEATURES END ======-->

<!--====== MENU PART START ======-->
<div class="menu-area style-2 bg-white menu-with-shape menu-nagative-gallery" style="display: none;">
    <!-- Gallery -->
    <!-- <div class="gallery-wrap">
        <div class="container">
            <ngx-slick-carousel class="row gallery-slider text-center" #slickModal="slick-carousel"
                [config]="gallerysliderConfig">
                <div ngxSlickItem class="col-lg-4" *ngFor='let item of gallerypost'>
                    <a routerLink="{{item.img}}" class="gallery-popup">
                        <img src="{{item.img}}" alt="">
                    </a>
                </div>
            </ngx-slick-carousel>
        </div>
    </div> -->
    <!-- Shape -->
    <div class="shape-two">
        <!-- <img src="{{shape}}" alt=""> -->
    </div>
</div>
<!--====== MENU PART END ======-->

<!--====== TEAM START ======-->
<!-- 
<section class="team-section bg-white has-slider pt-115 pb-85">
    <div class="container">
        <div class="section-title text-center mb-80">
            <span class="title-tag">Our Brokers</span>
            <h2>Talent We Rely On</h2>
        </div>
        <div class="row team-loop">
            <div class="col-lg-4 col-md-6" *ngFor='let item of teampost'>
                <div class="member-box">
                    <div class="member-img">
                        <img src="{{item.img}}" alt="">
                    </div>
                    <div class="member-info">
                        <h3>{{item.name}}</h3>
                        <span>{{item.post}}</span>
                    </div>
                    <a routerLink="#" class="socail-trigger">+</a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!--====== TEAM END ======-->

<!--====== Subscribe-map sec START ======-->
<!-- 
<div class="Subscribe-map-sec">
    <div class="footer-subscibe-area style-2 pt-50 pb-50">
        <div class="container">
            <div class="row justify-content-center align-items-center" *ngIf="!subscribed">
                <div class="col-lg-3">
                    <div class="subscribe">
                        <h3 class="mb-0 text-white">Get notifications of availability changes</h3>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="subscribe-text">
                        <form action="#" class="subscribe-form">
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <input type="text" placeholder="First Name" #subscribeFirstName>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <input type="text" placeholder="Last Name" #subscribeLastName>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <input type="email" placeholder="Your email" #subscribeEmail>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <button type="button" class="main-btn btn-filled btn-block"
                                        (click)="subscribe()">subscribe</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>


            <div class="row justify-content-center align-items-center" *ngIf="subscribed">
                <div class="col-lg-12">
                    <div class="subscribe">
                        <h3 class="mb-0 text-white">You have been successfully subscribed to the Fern Corner Update List
                        </h3>
                    </div>
                </div>



            </div>
        </div>
    </div>


</div> -->

<!--====== Subscribe-map sec END ======-->


<!--====== Subscribe-map sec START ======-->

<style>
    .fake-link{
        color:#4c6275;
        cursor: pointer;
    }
    .fake-link:hover {
        
  text-decoration: underline; }
</style>
<div class="Subscribe-map-sec">
    <div class="footer-subscibe-area style-2 pt-50 pb-50">
        <div class="container" style="background-color: white;border-radius: 10px;">
            <div class="row justify-content-center align-items-center" >
                <div class="col-lg-8">
                    <div class="subscribe" (click)="openFF()">
                        <h3 class="fake-link">Reserve on Furnished Finder!</h3>
                    </div>
                </div>
                <div class="col-lg-4">
                     <img src="/assets/img/FF-logo.webp" (click)="openFF()">
                </div>
            </div>         
        </div>
    </div>


</div>

<!--====== Subscribe-map sec END ======-->



<!--====== CALL TO ACTION END ======-->
<section class="pt-120 section cta-section dark-bg">

    <div style="width: 100%; height: 400px; position: absolute; top: 0; left: 0;  border:0;" class="contact-maps"
        id="map"></div>

    <div class="container">
        <div class="cta-inner">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-8 col-sm-9 col-10 order-2 order-lg-1">
                    <div class="cta-text">
                        <div class="section-title mb-20">
                            <span class="title-tag">coming to stay</span>
                            <h2>Fern Corner</h2>
                        </div>
                        <p>Located perfectly in Olympia's south west quarter. Minutes from Downtown and Minutes from
                            shopping. Parks and Playgrounds just a short walk away</p>
                        <!-- <a routerLink="#" class="main-btn btn-filled">take a tour</a> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-10 col-sm-11 col-10 order-1 order-lg-2">
                    <!-- feature loop -->
                    <div class="cta-features">
                        <!-- feature box -->
                        <div class="single-feature wow fadeInUp" data-wow-delay="{{item.animation}}"
                            *ngFor='let item of ctafeatures'>
                            <div class="icon">
                                <i class="{{item.icon}}"></i>
                            </div>
                            <div class="cta-desc">
                                <h3><a routerLink="#">{{item.title}}</a></h3>
                                <p [innerHTML]="item.text">

                                </p>
                                <span class="count">{{item.number}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== CALL TO ACTION END ======-->

<!-- About-us-style-2 Start -->
<div class="section about-style-2 pt-80 pb-50 ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-text-descr">
                    <p class="text-white mb-0">
                        Whether you're here for work or play, Fern Corner is the place to stay.
                        Everything you need for a comfortable stay in Olympia.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="section-title-wrap section-header">
                    <h5 class="custom-primary">Fern Corner</h5>
                    <h2 class="title text-white">Your Home Away From Home</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section latest-news pb-90">

    <div class="container">
        <div class="section-title text-center mb-50">
            <span class="title-tag">questions and answers about renting Fern Corner</span>
            <!-- <h2>FAQ</h2> -->
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                 
                <div class="title">
                    <h2>When can I come?</h2>
                </div>
                <p class="mb-25">
                    This is actually a family property. When relatives come to stay with their own families, if the
                    property isn't rented, they stay at the house. We can often re-arrange visits to suit the
                    needs of renters. For example, this summer a family had a tree fall through their roof. So, they
                    stayed at Fern Corner for 3 months while the contractors fixed everything. If
                    you need the house for a couple months, we can often re-arrange schedules so it works.
                </p>
                <div class="title">
                    <h2>How do I rent Fern Corner?</h2>
                </div>
                <p class="mb-25">
                    The property books up fairly quickly, so it is best to plan ahead. We often have people between
                    homes or staying while their own home is having renovations done.
                    The monthly rental cost is $3,400 plus a cleaning fee. We do consider weekly if there are openings
                    between longer term tenants, but generally the property books up quickly.                     
                </p>

               
                <div class="title">

                    <h2>Is it Suitable for business?</h2>
                </div>
                <p class="mb-25">
                    Absolutely. Each bedroom has a desk and the internet is nice and fast. When the legislature is in
                    session, Fern Corner makes a great head-quarters. With a huge table, fast internet, and such a good
                    location,
                    it is perfect for business groups. (Especially being just minutes from every food delivery place in
                    existance)
                </p>
                <div class="title">

                    <h2>What about Pets?</h2>
                </div>
                <p class="mb-25">
                    We allow <b>small</b> dogs, under 30lbs. We've had some major damage done by larger dogs, so it's
                    just not possible anymore. The backyard is fenced in and perfect for a small dog to run and play.
                    The neighborhood is very
                    dog friendly, with many owners walking their dogs. If you want to bring a cat, that's OK too, but
                    you'll need to pay an additional fee for a hypoallergenic cleaning. Some people have strong
                    allergies to cats, so it's simply required.
                </p>
                <div class="title">

                    <h2>What about Kids?</h2>
                </div>
                <p class="mb-25">
                    Absolutely not. Just kidding. :) There are fantasic schools in Olympia, some of the highest rated in
                    the state. Some people move here just to stay while they shop for a home in town, but want their
                    kids to be going to school as well. There are parks and nature trails everywhere. And if you've got
                    a teenager, the mall is walking distance.
                </p>
            </div>
        </div>
    </div>


</div>





<!-- About-us-style-2 End -->

<!--====== LATEST NEWS START ======-->
<!-- <div class="section latest-news pb-90">
    <div class="spacer dark-bg"></div>
    <div class="container">
         <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor='let item of blogpost'>
                <div class="latest-post-box mt-30">
                    <div class="post-img" style="background-image: url({{item.img}});"></div>
                    <div class="post-desc">
                        <ul class="post-meta">
                            <li>
                                <a routerLink="/news-details"><i class="fal fa-calendar-alt"></i>{{item.postdate}}</a>
                            </li>
                            <li>
                                <a routerLink="/news-details"><i class="fal fa-user"></i>By {{item.author}}</a>
                            </li>
                        </ul>
                        <h4><a routerLink="/news-details">{{item.title}}</a></h4>
                        <p>
                            {{item.text}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== LATEST NEWS END ======-->
<app-footerthree></app-footerthree>