import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import * as mapboxgl from 'mapbox-gl';
import $ from 'jquery';
import 'magnific-popup';
import { FernService } from 'src/app/services/fern.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homethree',
  templateUrl: './homethree.component.html',
  styleUrls: ['./homethree.component.css']
})
export class HomethreeComponent implements AfterViewInit {
  @ViewChild("bookingStart") bookingStart: ElementRef;
  @ViewChild("bookingEnd") bookingEnd: ElementRef;
  @ViewChild("roomCount") roomCount: ElementRef;

  @ViewChild("subscribeFirstName") subscribeFirstName: ElementRef;
  @ViewChild("subscribeLastName") subscribeLastName: ElementRef;
  @ViewChild("subscribeEmail") subscribeEmail: ElementRef;

  subscribed:boolean = false;


  


  constructor(private wowService: NgwWowService, public fern: FernService, private router:Router) {
    this.wowService.init();
  }
  // Banner
  // Original: { img: 'assets/img/banner/06.jpg', tag: 'The ultimate luxury experience', title: 'The Perfect Home For You', urltext1: 'take a tour', urltext2: 'Learn More' },
  bannerpost = [
    { img: 'assets/img/banner/dining-room-1920x700.jpg', tag: 'Your Home in Olympia', title: 'Comfort and Style', },//urltext1: 'take a tour', urltext2: 'Learn More' },
    { img: 'assets/img/banner/den-1920x700.jpg', tag: 'Your Home in Olympia', title: 'Relax and Enjoy', },//urltext1: 'take a tour', urltext2: 'Learn More' },
    { img: 'assets/img/banner/1340-Fern-1920x700.jpg', tag: 'Your Home in Olympia', title: 'The Perfect Home For You'}, //, urltext1: 'take a tour', urltext2: 'Learn More' },

  ];
  bannerConfig = {
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 2500,
    "dots": false,
    "fade": true,
    "arrows": false,
  }



  // Hand Picked Apartments
  handpickapartmentpost = [
    { img: 'assets/img/room-slider/den-kitchen-770.jpg', title: 'Family Room', text: 'Situated right next to the kitchen, the family room is a great place to relax, watch TV or just enjoy the fireplace.', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/entry-nook-770.jpg', title: 'Entry Nook', text: 'When meeting with guests, waiting for rides, or simply relaxing with a book.  The Entry nook, right next to the front door, is a great space.', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/kitchen-table-770.jpg', title: 'Kitchen Table', text: 'The kitchen table is in a great, well-lit, space.  You can have a quick meal and still have a great view of the Family Room to watch a movie', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/dining-room-770.jpg', title: 'Dining Room', text: 'Separated from the bustle of the family room and kitchen, the dining room offers a large table with 6 chairs that can be used for a family meal', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/dining-room-table-770.jpg', title: 'Dining Room', text: 'The dining room table makes an excellent place to have a meal, but can also be used as a great conference area for meetings', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/kitchen-770.jpg', title: 'Kitchen', text: 'A full kitchen, complete with toaster oven, and coffee maker are available for you. There are pots, pans, and cookware if you want to make a meal or a snack as well', price: '345', period: 'Month' },
    { img: 'assets/img/room-slider/kitchen2-770.jpg', title: 'Kitchen', text: 'Fully appointed with flatware and dishes, cloth napkins and dishtowels, a dishwasher and garbage disposal, you can be certain your needs will be met', price: '345', period: 'Month' },
  ];
  handpickapartmentSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    dots: false,
    centerMode: true,
    centerPadding: '28%',
    prevArrow:
      '<div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left"></i></div>',
    nextArrow:
      '<div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '5%',
        },
      },
    ],
  }




  // bedroom slider
  bedrooms = [
    { img: 'assets/img/room-slider/sam-room-770.jpg', title: 'Cascades', text: 'A nice bedroom on the second floor with a shared bath, large closet, dresser and desk.', price: '65', period: 'Day' },
    { img: 'assets/img/room-slider/lucille-room-770.jpg', title: 'Mount Rainier', text: 'Second Floor bedroom with a shared bath, large closet, dresser and desk.', price: '65', period: 'Day' },
    { img: 'assets/img/room-slider/first-floor-bedroom-770.jpg', title: 'Deschutes River', text: 'A small bedroom with a closet, desk, and dresser. Comes with your own private bathroom.', price: '65', period: 'Day' },
    { img: 'assets/img/room-slider/master-bedroom-770.jpg', title: 'Fern Master', text: 'A wonderful large room, complete with a private master bath and walk-in closet.', price: '119', period: 'Day' },
  ];
  bedroomSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    dots: false,
    centerMode: true,
    centerPadding: '28%',
    prevArrow:
      '<div class="slick-arrow prev-arrow"><i class="fal fa-arrow-left"></i></div>',
    nextArrow:
      '<div class="slick-arrow next-arrow"><i class="fal fa-arrow-right"></i></div>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '5%',
        },
      },
    ],
  }







  // Counter
  counterpost = [
    { icon: 'assets/img/icon/01.png', number: '84', prefix: 'k', title: 'Apartmentss in Album' },
    { icon: 'assets/img/icon/02.png', number: '10', prefix: 'M', title: 'Active Backers Around World' },
    { icon: 'assets/img/icon/03.png', number: '02', prefix: 'k', title: 'Categories Served' },
    { icon: 'assets/img/icon/04.png', number: '100', prefix: 'M', title: 'Ideas Raised Funds' },
  ];
  // Video
  videobg = 'assets/img/text-block/03.jpg';
  // Gallery
  shape = 'assets/img/icon/06.png';
  gallerypost = [
    { img: 'assets/img/menu/menu-gallery-1.jpg' },
    { img: 'assets/img/menu/menu-gallery-2.jpg' },
    { img: 'assets/img/menu/menu-gallery-3.jpg' },
    { img: 'assets/img/menu/menu-gallery-1.jpg' },
    { img: 'assets/img/menu/menu-gallery-2.jpg' },
    { img: 'assets/img/menu/menu-gallery-3.jpg' },
  ];
  gallerysliderConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  // Team
  teampost = [
    { img: 'assets/img/team/01.jpg', name: 'Rosa D.William', post: 'Founder & CEO' },
    { img: 'assets/img/team/02.jpg', name: 'hilixer b. browni', post: 'co-founder' },
    { img: 'assets/img/team/03.jpg', name: 'pokoloko k. kilix', post: 'consultant' },
  ];
  // cta-features
  ctafeatures = [
    { number: '01', icon: 'flaticon-car', animation: '.3s', title: 'Fast and Inexpensive Transport', text: 'If you\'re not driving yourself, a Lyft or Uber to the capital is about $10; even less to the mall down the street' },
    { number: '02', icon: 'flaticon-fast-food', animation: '.4s', title: 'Restaurant Options Nearby', 
      text: 'Olympia has a great selection of restuarants downtown and in the shopping district only minutes from the house, and almost everyone delivers.  With fast and easy access to everything, Fern Corner is situated perfectly' },
    { number: '03', icon: 'flaticon-swimming', animation: '.5s', 
        title: 'Parks and Playgrounds', text: 'If you have kids, <a href="https://goo.gl/maps/c7tb1bYL1E2qBGbv5" target="_blank"> Decatur Woods Park</a> is right down the street.  For a great hike, you can go down to  <a target="_blank" href="https://goo.gl/maps/KAqbGXCAWoHvNp1n6">Garfield Nature Trail Park</a>' },
  ];
  // Blog
  blogpost = [
    { img: 'assets/img/latest-post/01.jpg', title: 'Each of our 8 double rooms has its own distinct.', postdate: '28th Aug 2020', author: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
    { img: 'assets/img/latest-post/02.jpg', title: 'Each of our 8 double rooms has its own distinct.', postdate: '28th Aug 2020', author: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
    { img: 'assets/img/latest-post/03.jpg', title: 'Each of our 8 double rooms has its own distinct.', postdate: '28th Aug 2020', author: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.' },
  ];
  // Map
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/light-v10';
  antialias: true;
  pitch: 45;
  ngAfterViewInit(): void {
    // Counter
    ($('.counter') as any).each(function () {
      ($(this) as any).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function () {
          ($(this) as any).text(Math.ceil(this.Counter));
        },
      });
    });
    // Video popup
    ($('.popup-video') as any).magnificPopup({
      type: 'iframe',
    });
    // Map layout
    // Map
    (mapboxgl as any).accessToken =  environment.mapbox;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 12,
      pitch: 45,
      antialias: true,
      center: this.fern.lonLat
    });
    // Add map controls

    var marker = new mapboxgl.Marker()
      .setLngLat([-77.04, 38.907])
      .addTo(this.map);
    this.map.on('load', () => {
      // Insert the layer beneath any symbol layer.
      var layers = this.map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }
      this.map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
        labelLayerId
      );
    });

  }


  checkAvailability() {

    let start = this.bookingStart.nativeElement.value;
    let end = this.bookingEnd.nativeElement.value;
    let roomCount = this.roomCount.nativeElement.value;

    let subject = `Are ${roomCount} rooms available from ${start} to ${end}?`;
    this.fern.mailTo('dmorris@morrisdev.com', subject, '');
  }

   openFF(){
    console.log('going to FF')
    document.location.href ='https://www.furnishedfinder.com/property/342207_1';
  }

  async subscribe(){

    let fname = this.subscribeFirstName.nativeElement.value;
    let lname = this.subscribeLastName.nativeElement.value;
    let email = this.subscribeEmail.nativeElement.value;
    
    if(!this.fern.isEmail(email)){
      alert(`that's not a valid email`);
      return;
    }
   this.fern.addToList(fname, lname,email).subscribe((retval:any)=>{
    console.log('retval',retval)
    if(retval.status==='success'){
      this.subscribed=true;
    }
   },err=> console.log('error on subscription',err));
    

  }


}
