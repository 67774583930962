
<header class="header-absolute sticky-header" id="header">
    <div class="container container-custom-one">
        <div class="nav-container d-flex align-items-center justify-content-between">
            <!-- Main Menu -->
            <div class="nav-menu d-lg-flex align-items-center">

                <!-- Navbar Close Icon -->
                <div class="navbar-close">
                    <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                </div>

                <!-- Off canvas Menu  -->
                <div class="toggle">
                    <a routerLink="#" id="offCanvasBtn" (click)="offCanvasBtn()"><i class="fal fa-bars"></i></a>
                </div>
                <!-- Mneu Items -->
                <div class="menu-items">
                    <ul>
                        <li>
                            <a routerLink='/' routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                            <ul class="submenu">
                                <li><a routerLink='/' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Home One</a></li>
                                <li><a routerLink='/hometwo' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                                <li><a routerLink='/homethree' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                            </ul>
                        </li>
                        <li>
                            <a routerLink='/apartment-grid' routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Apartments</a>
                            <ul class="submenu">
                                <li><a routerLink='/apartment-details' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Apartments Details</a></li>
                                <li><a routerLink='/trending' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Apartments List 2</a></li>
                                <li><a routerLink='/apartment-grid' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Apartments Grid</a></li>
                                <li><a routerLink='/apartment-list' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Apartments List</a></li>
                            </ul>
                        </li>
                        <li>
                            <a routerLink='/news' routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">News</a>
                            <ul class="submenu">
                                <li><a routerLink='/news-details' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">News Details</a></li>
                            </ul>
                        </li>
                        <li><a routerLink="#" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Pages</a>
                            <ul class="submenu">
                                <li>
                                    <a routerLink='/places' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Places Near By</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/places-details' routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Places Details</a></li>
                                    </ul>
                                </li>
                                <li><a routerLink='/classification' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Classification</a></li>
                                <li><a routerLink='/gallery' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Our Gallery</a></li>
                                <li><a routerLink='/contact' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                                <li><a routerLink='/about' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                <li><a routerLink='/broker' routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Brokers</a></li>

                            </ul>
                        </li>
                    </ul>
                </div>

                <!-- from pushed-item -->
                <div class="nav-pushed-item"></div>
            </div>

            <!-- Site Logo -->
            <div class="site-logo">
                <a routerLink='/'><img src="assets/img/logo.png" alt="Logo"></a>
            </div>

            <!-- Header Info Pussed To Menu Wrap -->
            <div class="nav-push-item">
                <!-- Header Info -->
                <div class="header-info d-lg-flex align-items-center">
                    <div class="item">
                        <i class="fal fa-phone"></i>
                        <span>Phone Number</span>
                        <a routerLink="tel:+90898787709">
                            <h5 class="title">{{fern.contact.phone}}</h5>
                        </a>
                    </div>
                    <div class="item">
                        <i class="fal fa-envelope"></i>
                        <span>Email Address</span>
                        <a routerLink="mailto:{{fern.contact.email}}">
                            <h5 class="title">{{fern.contact.email}}</h5>
                        </a>
                    </div>
                </div>
            </div>

            <!-- Navbar Toggler -->
            <div class="navbar-toggler">
                <span></span><span></span><span></span>
            </div>
        </div>
    </div>
</header>
<div class="offcanvas-wrapper" [ngClass]="opencanvas ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="opencanvas ? '' : 'show-overly'" (click)="offCanvasclose()"></div>
    <div class="offcanvas-widget">
        <a href="#" class="offcanvas-close" (click)="offCanvasclose()"><i class="fal fa-times"></i></a>
        <app-canvas></app-canvas>
    </div>
</div>