import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import $ from 'jquery';
import 'magnific-popup';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  // gallery
  gallerypost = [
    {img:'assets/img/gallery/01.jpg',animation:'.3s'},
    {img:'assets/img/gallery/02.jpg',animation:'.4s'},
    {img:'assets/img/gallery/03.jpg',animation:'.5s'},
    {img:'assets/img/gallery/04.jpg',animation:'.6s'},
    {img:'assets/img/gallery/05.jpg',animation:'.7s'},
    {img:'assets/img/gallery/06.jpg',animation:'.8s'},
    {img:'assets/img/gallery/07.jpg',animation:'.9s'},
    {img:'assets/img/gallery/08.jpg',animation:'1.1s'},
  ];
  ngOnInit(): void {
    // Video popup
    ($('.gallery-loop .popup-image') as any).magnificPopup({
      type: 'image',
				gallery: {
					enabled: true,
				},
				mainClass: 'mfp-fade',
    });
  }

}
