<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb> 
<section class="offers-area pt-120 pb-60">
    <div class="container">
        <div class="offer-boxes-loop">
            <div class="offer-box" *ngFor='let item of trendingpost'>
                <div class="thumb">
                    <img src="{{item.img}}" alt="">
                </div>
                <div class="offer-desc">
                    <div class="title-wrap">
                        <div class="title">
                            <span class="room-cat">{{item.category}}</span>
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="price">
                            ${{item.price}}<span>/{{item.period}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-lg-6">
                            <div class="offer-text">
                                <p>
                                    {{item.text}}
                                </p>
                                <a routerLink="#" class="main-btn btn-filled">Book now</a>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="offer-feature">
                                <ul>
                                    <li><i class="fal fa-bath"></i> Air conditioner</li>
                                    <li><i class="fal fa-wifi"></i>High speed WiFi</li>
                                    <li><i class="fal fa-cut"></i>Breakfast</li>
                                    <li><i class="fal fa-guitar"></i>Kitchen</li>
                                    <li><i class="fal fa-broom"></i>Cleaning</li>
                                    <li><i class="fal fa-shower"></i>Shower</li>
                                    <li><i class="fal fa-shopping-basket"></i>Grocery</li>
                                    <li><i class="fal fa-bed"></i>Single bed</li>
                                    <li><i class="fal fa-shopping-cart"></i>shop near</li>
                                    <li><i class="fal fa-bus"></i>Towels</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FEATURE ROOM START ======-->
<section class="feature-room-section with-bg pt-155 pb-115" style="background-image: url({{featuresbg}});">
    <div class="container">
        <div class="section-title white-color text-center">
            <span class="title-tag">popular list</span>
            <h2>Popular Apartments</h2>
        </div>
        <!-- Apartment Looop -->
        <div class="row room-gird-loop justify-content-center mt-30">
            
            <div class="col-lg-4 col-sm-6" *ngFor='let item of popularapartment'>
                <div class="room-box mt-30">
                    <div class="room-img-wrap">
                        <div class="room-img" style="background-image: url({{item.img}});"></div>
                    </div>
                    <div class="room-desc">
                        <ul class="icons">
                            <li><i class="fal fa-bed"></i></li>
                            <li><i class="fal fa-wifi"></i></li>
                            <li><i class="fal fa-car"></i></li>
                            <li><i class="fal fa-coffee"></i></li>
                            <li><i class="fal fa-concierge-bell"></i></li>
                            <li><i class="fal fa-compress-arrows-alt"></i></li>
                            <li><i class="fal fa-swimmer"></i></li>
                        </ul>
                        <h4 class="title"><a routerLink="/apartment-details">{{item.title}}</a></h4>
                        <p>{{item.text}}</p>
                        <span class="price">${{item.price}}/{{item.period}}</span>
                        <a routerLink="/apartment-details" class="book-btn">Booking Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====== FEATURE ROOM END ======-->
<app-footerinner></app-footerinner>