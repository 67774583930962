<app-headerfour></app-headerfour>
<app-breadcrumb></app-breadcrumb>
<!--====== ROOM-GRIRD START ======-->
<section class="room-section room-grid-style pt-120 pb-120">
    <div class="container">
        <div class="row justify-content-center room-gird-loop">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor='let item of appartmentgridpost'>
                <div class="room-box mb-30">
                    <div class="room-img-wrap">
                        <div class="room-img" style="background-image: url({{item.img}});"></div>
                    </div>
                    <div class="room-desc">
                        <ul class="icons">
                            <li><i class="fal fa-bed"></i></li>
                            <li><i class="fal fa-wifi"></i></li>
                            <li><i class="fal fa-car"></i></li>
                            <li><i class="fal fa-coffee"></i></li>
                            <li><i class="fal fa-concierge-bell"></i></li>
                            <li><i class="fal fa-compress-arrows-alt"></i></li>
                            <li><i class="fal fa-swimmer"></i></li>
                        </ul>
                        <h4 class="title"><a routerLink="/apartment-details">{{item.title}}</a></h4>
                        <p>{{item.text}}</p>
                        <span class="price">${{item.price}}/{{item.period}}</span>
                        <a routerLink="/apartment-details" class="book-btn">Booking Now</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-wrap">
            <app-pagination></app-pagination>
        </div>
    </div>
</section>
<!--====== ROOM-GRIRD END ======-->
<app-footerinner></app-footerinner>