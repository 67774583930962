import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apartmentlist',
  templateUrl: './apartmentlist.component.html',
  styleUrls: ['./apartmentlist.component.css']
})
export class ApartmentlistComponent implements OnInit {

  constructor() { }
  appartmentlistpost = [
    {
      img: 'assets/img/restaurant/basilico.jpg',
      title: 'Basilico Ristorante Italiano',
      text: `True Italian food and wine at Basilico Ristorante Italiano located in downtown Olympia. 
          We have a passion and knowledge of Italian food and wine and that alone makes Basilico a
          true authentic fine dining Italian restaurant in Olympia. Basilico stands out for its high 
          level of freshness, quality and true authenticity. Here everything is truly made from the 
          scratch, and we do not compromise on that. Basilico also stands for celebration of life, 
          where everyone is invited to enjoy those rare moments of joy combined with fine Italian 
          food and wine in a cozy, friendly and elegant atmosphere.`,
      mapLink: `https://www.google.com/maps/place/Basilico+Ristorante/@47.0437848,-122.9015188,17z/data=!4m8!1m2!2m1!1srestaurants+in+Olympia!3m4!1s0x5491751c6369badb:0x634d388f904bb73d!8m2!3d47.043772!4d-122.901695`,
      menuLink:`assets/menus/basilico-ristorante-italiano.pdf`,
      price: '', phone: '(360) 570-8777', period: 'Month', website: 'http://www.ristorantebasilico.com/'
    },
    {
      img: 'assets/img/restaurant/cascadia.jpg',
      title: 'Cascadia Grill', 
      text: ` A Northwest brasserie, celebrating the independent spirit, food, wine, and craft beer of the Pacific Northwest. ...And Bigfoot! Now open for Phase 1 curbside carryout and sidewalk dining.
        Also featuring a wide selection of <a href="assets/menus/cascadia-vegan.pdf" class="simple-link">vegan dishes</a>.`, 
      price: '345', period: 'Month',
      mapLink:'https://www.google.com/maps/place/Cascadia+Grill/@47.0448023,-122.9031364,17z/data=!4m8!1m2!2m1!1srestaurants+in+Olympia!3m4!1s0x5491751b84de516b:0xb543a3c8da092405!8m2!3d47.044909!4d-122.903154',
      menuLink:'',
      phone:'(360) 628-8731',website:'https://cascadiagrill.com/',
    },    
    {
      img: 'assets/img/restaurant/cascadia.jpg',
      title: 'Cascadia Grill', 
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua', 
      price: '345', period: 'Month',
      mapLink:'https://www.google.com/maps/place/Cascadia+Grill/@47.0448023,-122.9031364,17z/data=!4m8!1m2!2m1!1srestaurants+in+Olympia!3m4!1s0x5491751b84de516b:0xb543a3c8da092405!8m2!3d47.044909!4d-122.903154',
      menuLink:'',
      phone:'',website:'',
    },
    {
      img: 'assets/img/restaurant/cascadia.jpg',
      title: 'Cascadia Grill', 
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua', 
      price: '345', period: 'Month',
      mapLink:'',menuLink:'',
      phone:'',website:'',
    },
    {
      img: 'assets/img/restaurant/cascadia.jpg',
      title: 'Cascadia Grill', 
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua', 
      price: '345', period: 'Month',
      mapLink:'',menuLink:'',
      phone:'',website:'',
    },
    // { img: 'assets/img/room/list-3.jpg', title: 'Minimal Duplex Apartment', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', price: '345', period: 'Month' },
    ];
  ngOnInit(): void {
  }

}
