import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.css']
})
export class BrokerComponent implements OnInit {

  constructor() { }
  // Team
  teamblock = [
    {img:'assets/img/team/01.jpg',name:'Rosa D.William',post:'Founder & CEO'},
    {img:'assets/img/team/02.jpg',name:'hilixer b. browni',post:'co-founder'},
    {img:'assets/img/team/03.jpg',name:'pokoloko k. kilix',post:'consultant'},
    {img:'assets/img/team/04.jpg',name:'pokoloko k. kilix',post:'consultant'},
    {img:'assets/img/team/05.jpg',name:'pokoloko k. kilix',post:'consultant'},
    {img:'assets/img/team/01.jpg',name:'Rosa D.William',post:'Founder & CEO'},
  ];

  ngOnInit(): void {
  }

}
