<!-- Search Widget -->
<div class="widget search-widget">
    <h5 class="widget-title">Search room</h5>
    <form action="#">
        <input type="text" placeholder="Search your keyword...">
        <button type="submit"><i class="far fa-search"></i></button>
    </form>
</div>

<!-- About Widget -->
<div class="widget about-widget">
    <h5 class="widget-title">About us</h5>
    <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia reiciendis illo ipsa asperiores,
        perspiciatis corrupti veritatis assumenda architecto commodi provident quas necessitatibus
        consequatur praesentium magnam optio deserunt fugiat repellat culpa.
    </p>
</div>
<!-- Nav Widget -->
<div class="widget nav-widget">
    <h5 class="widget-title">Our pages</h5>
    <ul>
        <li><a routerLink='/about'>About Us</a></li>
        <li><a routerLink='/classification'>Classification</a></li>
        <li>
            <a routerLink='/places'>Places Near By</a>
            <ul class="submenu">
                <li><a routerLink='/places-details'>Places Details</a></li>
            </ul>
        </li>
        <li>
            <a routerLink='/trending'>Apartment</a>
            <ul class="submenu">
                <li><a routerLink='/apartment-details'>Apartment Details</a></li>
                <li><a routerLink='/trending'>Apartment List 2</a></li>
                <li><a routerLink='/apartment-grid'>Apartment Grid</a></li>
                <li><a routerLink='/apartment-list'>Apartment List</a></li>
            </ul>
        </li>
        <li>
            <a routerLink='/news'>News</a>
            <ul class="submenu">
              <li><a routerLink='/news-details'>News Details</a></li>
            </ul>
        </li>
        <li><a routerLink='/gallery'>Our Gallery</a></li>
        <li><a routerLink='/broker'>Brokers</a></li>
        <li><a routerLink='/contact'>Contact Us</a></li>
    </ul>
</div>
<!-- Social Link -->
<div class="widget social-link">
    <h5 class="widget-title">Contact with us</h5>
    <ul>
        <li><a routerLink="#"><i class="fab fa-facebook-f"></i></a></li>
        <li><a routerLink="#"><i class="fab fa-twitter"></i></a></li>
        <li><a routerLink="#"><i class="fab fa-behance"></i></a></li>
        <li><a routerLink="#"><i class="fab fa-linkedin"></i></a></li>
        <li><a routerLink="#"><i class="fab fa-google"></i></a></li>
    </ul>
</div>