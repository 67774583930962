import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor() { }

  blogimg = 'assets/img/blog/01.jpg';
  blogimg2 = 'assets/img/blog/02.jpg';
  blogimg3 = 'assets/img/blog/03.jpg';
  blogimg4 = 'assets/img/blog/06.jpg';
  smallauthor = 'assets/img/author-small.png';

  ngOnInit(): void {
  }

}
