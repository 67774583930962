<div class="instagram-feed-section">
    <div class="container-fluid p-0">
        <ngx-slick-carousel class="instagram-slider" #slickModal="slick-carousel" [config]="instasliderConfig">
            <div ngxSlickItem class="image" *ngFor='let item of instapost'>
                <a href="{{item.img}}" class="insta-popup">
                    <img src="{{item.img}}" alt="instagram">
                </a>
            </div>
        </ngx-slick-carousel>
    </div>
</div>